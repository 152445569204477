import instagramLogo from "../assets/images/instagram_logo.svg";
import facebookLogo from "../assets/images/facebook_logo.svg";
import telegramLogo from "../assets/images/telegram_logo.svg";
import tikTokLogo from "../assets/images/tiktok_logo.svg";
import xLogo from "../assets/images/x_logo.svg";
import TaskCard from "./TaskCard";
import SocialMediaTaskBottomSheet from "./SocialMediaTaskBottomSheet";
import { useState, useMemo } from "react";
import { useGetSocialMediaTasksQuery } from "../services/official";
import { useSelector } from "react-redux";

const platformToIcon = {
  instagram: instagramLogo,
  facebook: facebookLogo,
  telegram: telegramLogo,
  x: xLogo,
  tiktok: tikTokLogo,
};

const SocialMediaTaskCard = ({ platform, type, rewardInAirdropPoints, handleAction }) => {
  const [isBottomSheetOpen, setBottomSheetOpen] = useState(false);
  const { id: userId } = useSelector((state) => state.user);
  const { data: tasks } = useGetSocialMediaTasksQuery(
    { userId },
    { keepPreviousData: true }
  );
  const task = useMemo(
    () =>
      tasks?.find(
        (task) =>
          task.platform.toLowerCase() === platform &&
          task.type.toLowerCase() === type
      ),
    [tasks, platform, type]
  );
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const title = useMemo(() => {
    if (type === "share") {
      return `Share on ${capitalize(platform)}`;
    }
    if (type === "follow") {
      return `Follow on ${capitalize(platform)}`;
    }
    return "";
  }, [type, platform]);

  const actionText = useMemo(() => capitalize(type), [type]);

  return (
    <>
      <SocialMediaTaskBottomSheet
        moderationStartedAt={task?.moderationStartedAt}
        handleAction={handleAction}
        taskId={task?.id}
        icon={platformToIcon[platform]}
        title={title}
        actionButtonText={actionText}
        rewardInAirdropPoints={rewardInAirdropPoints}
        isOpen={isBottomSheetOpen}
        close={() => setBottomSheetOpen(false)}
      />
      <button
        disabled={task?.isCompleted}
        onClick={() => setBottomSheetOpen(true)}
        className="w-full"
      >
        <TaskCard
          icon={platformToIcon[platform]}
          title={title} 
          reward={`${rewardInAirdropPoints}`}
          isCompleted={task?.isCompleted}
        />
      </button>
    </>
  );
};

export default SocialMediaTaskCard;
