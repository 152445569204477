export const Badge = ({ icon, text, isDisabled = false, onClick }) => {
  return (
    <div style={{ filter: isDisabled ? "grayscale(1)" : "" }}>
      <button
        onClick={onClick}
        disabled={isDisabled}
        className={`flex flex-row justify-start items-center rounded-lg h-[calc(2rem+3px)] mb-[3px] ${
          icon ? "" : "px-2"
        }`}
        style={{
          background: "linear-gradient(to bottom, #15CF05, #91F934)",
          filter: "drop-shadow(0px 3px 0px #46CC1C)",
          boxShadow: "inset 0px -3px 0px #C3FD70",
        }}
      >
        {icon && (
          <div className="flex flex-row items-center">
            {icon}
            <div
              className="h-5 rounded-lg bg-[#91F934]"
              style={{
                borderColor: "#46CC1C",
                borderWidth: "0.93px",
                width: "2px",
              }}
            ></div>
          </div>
        )}

        <div className="flex flex-row flex-grow items-center justify-center min-w-[12vw] text-xs font-bold text-[#1E0F6A]">
          {text}
        </div>
      </button>
    </div>
  );
};
