import { useState, useEffect } from "react";

const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }
    else if (/android/i.test(userAgent)) {
        return 'Android';
    }
    else {
        return 'unknown';
    }
};

const useKeyboardVisibility = () => {
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);
    const isMobileDevice = () => {
        return /Mobi|Android/i.test(window.navigator.userAgent);
    };
    useEffect(() => {
        if (!isMobileDevice()) return;
        const onFocus = (e) => {
            const target = e.target;
            setKeyboardVisible(true);
            target.scrollIntoView({
                block: "center",
                behavior: "smooth",
            });
        };

        const onBlur = (e) => {
            if (e.relatedTarget?.tagName !== "INPUT") {
                window.scrollTo({ behavior: "smooth", top: 0 });
                setKeyboardVisible(false);
            }
        };

        document.querySelectorAll("input").forEach((input) => {
            input.addEventListener("focus", onFocus);
            input.addEventListener("blur", onBlur);
        });

        return () => {
            document.querySelectorAll("input").forEach((input) => {
                input.removeEventListener("focus", onFocus);
                input.removeEventListener("blur", onBlur);
            });
        };
    }, []);

    return isKeyboardVisible;
};

export { useKeyboardVisibility, getMobileOperatingSystem };
