const Card = ({ label, icon, leftText, rightElement, onClick }) => {
  return (
    <button
      className="w-full flex flex-col items-start"
      disabled={onClick === undefined}
      onClick={onClick}
    >
      {label && <div className="text-xs mb-[0.5rem] opacity-35">{label}</div>}
      <div
        style={{
          borderColor: "rgba(66, 43, 210, 0.45)",
          backgroundColor: "rgba(33, 18, 123, 0.45)",
        }}
        className="h-14 rounded-xl border-2 flex flex-col justify-between pl-1 pr-3 w-full"
      >
        <div className="flex justify-between w-full pl-3 flex-grow items-center text-sm">
          <div className="flex flex-row items-center gap-3">
            {icon}
            <div className="flex flex-row">{leftText}</div>
          </div>
          {rightElement}
        </div>
      </div>
    </button>
  );
};

export default Card;
