import { useState } from "react";
import { ImageButton } from "./ImageButton";
import CopyToClipboard from "react-copy-to-clipboard";
import copyToClipboardIcon from "../assets/images/copy_to_clipboard_icon.svg";
import TickIcon from "./TickIcon";

const CopyAddressField = ({ address, label }) => {
  const [isCopied, setCopied] = useState(false);
  return (
    <div className="w-full">
      <div className="text-xs mb-[0.5rem] opacity-35">{label}</div>{" "}
      <div
        className={`rounded-xl border-2 overflow-hidden mt-[0.5rem] flex flex-row items-center justify-between h-14  px-3 w-full`}
        style={{
          borderColor: "rgba(66, 43, 210, 0.75)",
          backgroundColor: "rgba(33, 18, 123, 0.45)",
        }}
      >
        <div className="overflow-hidden whitespace-nowrap text-ellipsis text-[0.65rem] pr-2">
          {address}
        </div>
        <div
          style={isCopied ? { display: "none" } : null}
          className="w-5 flex justify-end"
        >
          <CopyToClipboard text={address}>
            <ImageButton
              imageSrc={copyToClipboardIcon}
              alt="Copy to clipboard icon"
              onClick={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
              }}
            />
          </CopyToClipboard>
        </div>
        <div style={isCopied ? null : { display: "none" }}>
          <TickIcon size="1.25rem" color="rgba(255, 255, 255, 0.55)" />
        </div>
      </div>
    </div>
  );
};

export default CopyAddressField;
