
import { motion } from "framer-motion";

const ScaleUpAnimation = ({ children }) => {
  return (
    <motion.div
      initial={{ scale: 0.8 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", stiffness: 260, damping: 20 }}
      style={{ transformOrigin: 'center' }} // Add this line
      layout
    >
      {children}
    </motion.div>
  );
};


export default ScaleUpAnimation;