import RightArrowButton from "./RightArrowButton";
import dollarCoinIcon from "../assets/images/dollar_coin_icon.png";
import { formatDate } from "../utils/time";
import StatusIndicator from "./StatusIndicator";

const WithdrawalHistoryCard = ({
  amount,
  date,
  status,
  transactionHash,
  transactionLink,
}) => {
  const formattedDate = formatDate(date);

  return (
    <div>
      <div
        style={{
          borderColor: "rgba(66, 43, 210, 0.45)",
          backgroundColor: "rgba(33, 18, 123, 0.45)",
        }}
        className="h-14 rounded-xl border-2 flex flex-col justify-between pl-1 pr-3"
      >
        <div className="flex justify-between w-full pl-3 flex-grow items-center text-sm">
          <div className="flex flex-row items-center gap-3">
            <img src={dollarCoinIcon} alt="dollar coin icon" className="h-6" />
            <div className="gap-3 flex flex-row">
              <span>
                <span style={{ opacity: 1 }}>
                  ${amount?.toString().split(".")[0]}
                </span>
                <span style={{ opacity: 0.35 }}>
                  .{amount?.toString()?.split(".")[1]}
                </span>
              </span>
            </div>{" "}
          </div>
          <div className="flex flex-row items-center justify-end">
            <div className="flex-grow text-right opacity-35 pr-3">
              {formattedDate}
            </div>
            <RightArrowButton
              isDisabled={status !== "Completed"}
              onClick={() => {
                window.open(transactionLink, "_blank");
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between text-right text-xs mt-1 px-4">
        <div className="overflow-hidden whitespace-nowrap text-ellipsis w-1/2 opacity-35">
          {transactionHash}
        </div>
        <StatusIndicator status={status} />
      </div>
    </div>
  );
};

export default WithdrawalHistoryCard;
