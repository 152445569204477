import Countdown from "react-countdown";

const CountDownTimer = ({ date }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span className="text-lg font-semibold text-white">
          Contest finished!
        </span>
      );
    }

    return (
      <div className="flex space-x-1 text-center justify-center">
        <div className="text-white">
          <span className="block text-lg font-bold mb-[-0.5rem]">
            {String(days).padStart(2, "0")}
          </span>
          <span className="text-xs">days</span>
        </div>
        <div className="mt-[0.1rem]">:</div>
        <div className="text-white">
          <span className="block text-lg font-bold mb-[-0.5rem]">
            {String(hours).padStart(2, "0")}
          </span>
          <span className="text-xs">hrs</span>
        </div>
        <div className="mt-[0.1rem]"> :</div>

        <div className="text-white">
          <span className="block text-lg font-bold mb-[-0.5rem]">
            {String(minutes).padStart(2, "0")}
          </span>
          <span className="text-xs">mins</span>
        </div>
        <div className="mt-[0.1rem]">:</div>

        <div className="text-white">
          <span className="block text-lg font-bold mb-[-0.5rem]">
            {String(seconds).padStart(2, "0")}
          </span>
          <span className="text-xs">secs</span>
        </div>
      </div>
    );
  };
  return <Countdown date={date} renderer={renderer} />;
};

export default CountDownTimer;
