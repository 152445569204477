const StatusIndicator = ({ status }) => {
  const statusIdToReadable = {
    Completed: "Completed",
    WaitingForApproval: "Waiting for verification",
    Rejected: "Rejected",
    Failed: "Failed",
  };

  const backgroundColors = {
    Completed: "#0FB601",
    WaitingForApproval: "#EDFF00",
    Rejected: "#FF0088",
    Failed: "#FF0088",
  };

  const borderColors = {
    Completed: "rgba(15, 182, 1, 0.45)",
    WaitingForApproval: "rgba(237, 255, 0, 0.45)",
    Rejected: "rgba(255, 0, 136, 0.45)",
    Failed: "rgba(255, 0, 136, 0.45)",
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <div
        className="w-1.5 h-1.5 rounded-full"
        style={{
          backgroundColor: backgroundColors[status],
          boxShadow: `0 0 0 1.5px ${borderColors[status]}`, // Outer "border" with box-shadow (change color and width as needed)
        }}
      />
      <div className="opacity-35">{statusIdToReadable[status]}</div>
    </div>
  );
};
export default StatusIndicator;
