import { motion } from "framer-motion";

const PulsatingAnimation = ({ children, isPulsating }) => {
  return (
    <motion.div
      initial={{ scale: 1 }} // Start at normal size
      animate={isPulsating ? { scale: [1, 1.1, 1] } : { scale: 1 }} // Animate or stop based on isPulsating
      transition={{
        duration: 1, // Duration of one pulsation cycle
        repeat: isPulsating ? Infinity : 0, // Only repeat if isPulsating is true
        ease: "easeInOut", // Smooth easing for the pulse
      }}
      style={{ transformOrigin: "center" }} // Ensures pulsation happens from the center
    >
      {children}
    </motion.div>
  );
};

export default PulsatingAnimation;