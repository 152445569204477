import TaskCard from "./TaskCard";
import dollarIcon from "../assets/images/dollar_icon.png";
import { useGetGleamCodeRewardsQuery } from "../services/official";
import { useMemo } from "react";

const DepositTaskCard = ({}) => {
  const { data } = useGetGleamCodeRewardsQuery();
  console.log(data);

  const tenDollarDepositReward = useMemo(() => {
    const rewards = data?.gleamCodeRewards;
    return rewards?.find((reward) => reward.type === "TenDollarDeposit");
  }, [data]);

  const usedDepositCodeCount = useMemo(() => {
    return data?.codeStatistics?.usedTenDollarDepositCodeCount;
  }, [data]);

  const totalDepositCodeCount = useMemo(() => {
    return data?.codeStatistics?.totalTenDollarDepositCodeCount;
  }, [data]);

  return (
    <div>
      <TaskCard
        link="https://gleam.io/LK8qr/-win-5000-in-casino-coins-in-our-exclusive-airdrop-"
        isRewardInCC={false}
        icon={dollarIcon}
        title={"Deposit to earn!"}
        reward={"Reveal Code"}
        tooltipId={"deposit-task-tooltip"}
        secretCode={tenDollarDepositReward?.code}
        progress={{
          unitsCompleted: Math.min(data?.currentDepositAmountInUsd, 10).toFixed(
            2
          ),
          goal: data?.requiredDepositAmountInUsd.toFixed(2),
        }}
        progressUnit={"$"}
        isCompleted={false}
        buttonText={true && "Show code"}
        onButtonClick={() => {}}
        iconClassName={"w-6 opacity-30"}
      />
      <div className="text-xs opacity-30 mr-5 mt-1 text-right">
        Codes available: {totalDepositCodeCount - usedDepositCodeCount}/
        {totalDepositCodeCount}
      </div>
    </div>
  );
};

export default DepositTaskCard;
