import airdropCoinIcon from "../assets/images/airdrop_coin_icon.png";

const AirdropCoinIcon = ({className}) => {
  return (
    <img
      src={airdropCoinIcon}
      alt="Airdrop coin icon"
      className={className}
      style={{
        filter: `
  drop-shadow(0px 1.86px 11px rgba(223, 188, 81, 0.55)) 
  drop-shadow(0px 1.86px 3.18px rgba(255, 237, 165, 0.17))
`,
      }}
    />
  );
};


export default AirdropCoinIcon;