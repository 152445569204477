export const ImageButton = ({ imageSrc, alt, className, onClick, style }) => {
  return (
    <button onClick={onClick} style={{backgroundColor: 'transparent'}}>
      <img
        src={imageSrc}
        alt={alt}
        style={style}
        className={`object-cover size-full ${className}`}
      />
    </button>
  );
};
