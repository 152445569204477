const IconButton = ({ icon, onClick, className, isSelected = false }) => {
  const background = isSelected
    ? "linear-gradient(to bottom, #4435A3, #695FA8)"
    : "linear-gradient(to bottom, #280EC7, #4734BD)";
  const filter = isSelected
    ? `
    drop-shadow(0px 1px 0px #8178B5) 
       drop-shadow(0px 4px 0px #5042A5)
    `
    : `drop-shadow(0px 1px 0px #6352CE) 
       drop-shadow(0px 4px 0px #3720C3)
          `;
  return (
    <button
      onClick={onClick}
      style={{
        aspectRatio: "1",
        background,
        filter,
      }}
      className="h-8 flex items-center justify-center rounded-md mb-[5px]"
    >
      <img src={icon} className={`w-auto h-5 ${className}`} />
    </button>
  );
};

export default IconButton;
