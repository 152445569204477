const UpdateCard = ({ text, date }) => {
  return (
    <div className="h-full flex flex-col items-center justify-start text-sm w-full">
      <div
        style={{
          backgroundColor: "rgba(66, 43, 210, 0.45)",
        }}
        className="w-full rounded-xl flex flex-col items-start justify-between px-3 py-2 gap-3 text-sm"
      >
        {text}
        <div className="right-3 opacity-30 self-end text-xs">{date}</div>
      </div>
    </div>
  );
};

export default UpdateCard;