const CircleIconButton = ({
  icon,
  onClick,
  isDisabled,
  gradientColorTop,
  gradientColorBottom,
}) => (
  <button
    className="rounded-full h-[55%] aspect-square flex items-center justify-center"
    style={{
      background: `linear-gradient(to bottom, ${gradientColorTop}, ${gradientColorBottom})`,
      boxShadow:
        "0 7px 3px rgba(0, 0, 0, 0.2), inset 0px -4px 0px rgba(0, 0, 0, 0.2), inset 0px 4.95px 8px rgba(255, 255, 255, 0.41)", // y=7, blur=3, color=black with 20% opacity
      borderColor: "#F7D0FF",
      borderWidth: "1px",
      backdropFilter: "blur(4px)",
      filter: isDisabled ? "grayscale(100%)" : null,
    }}
    disabled={isDisabled}
    onClick={onClick}
  >
    <img
      src={icon}
      className="w-4 h-auto absolute z-[2]"
      style={{
        mixBlendMode: "overlay",
      }}
    />
  </button>
);

export default CircleIconButton;
