import { useGetTopReferrersQuery } from "../../services/official";
import firstPlaceBadge from "../../assets/images/first_place_badge.png";
import secondPlaceBadge from "../../assets/images/second_place_badge.png";
import thirdPlaceBadge from "../../assets/images/third_place_badge.png";
import { useCallback, useMemo } from "react";

const TopReferrersTabContent = () => {
  const { data } = useGetTopReferrersQuery();
  const contestEndDate = useMemo(() => Date.parse("2024-11-14T9:59:59Z"), []);

  const getIndexOrBadge = useCallback(
    (index) => {
      if (index > 2 || Date.now() > contestEndDate) {
        return (
          <div className="h-11 aspect-[99/128] flex items-center justify-center">
            {index + 1}.
          </div>
        );
      }

      const badges = [firstPlaceBadge, secondPlaceBadge, thirdPlaceBadge];
      return <img src={badges[index]} className="w-auto h-11" />;
    },
    [contestEndDate]
  );

  return (
    <div className="flex flex-col items-center pt-3 w-full px-5 mb-5">
      <div className="text-2xl agbalumo-regular ">Top Referrers</div>
      <div className="flex flex-col item-start w-full">
        <div className="text-xs mb-[0.5rem] opacity-35 mt-5"></div>
        {data?.topReferrers?.length == 0 && (
          <div className="text-sm opacity-35 h-full flex flex-col justify-center items-center">
            No referred friends yet
          </div>
        )}
        <div className="flex flex-col gap-2">
          {data?.topReferrers?.length > 0 && (
            <>
              <div className="text-xs mb-[0.5rem] opacity-35 flex flex-row justify-between">
                <div>Name</div>
                <div>Referrals</div>
              </div>
              {data?.topReferrers.map((user, index) => (
                <div
                  style={{
                    backgroundColor: "rgba(66, 43, 210, 0.45)",
                  }}
                  className="h-12 w-full rounded-xl flex flex-row items-center justify-between pl-2 pr-3 text-sm"
                  key={index}
                >
                  <div className="flex flex-row items-center">
                    <span className="pr-3">{getIndexOrBadge(index)}</span>{" "}
                    {user?.userName}
                  </div>
                  <div className="opacity-30">{user?.referredUsersCount}</div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopReferrersTabContent;
