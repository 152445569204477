export  const reelSymbols = [
    "Uniswap",
    "BNB",
    "CRO",
    "Monero",
    "Shiba",
    "Shiba",
    "Doge",
    "Shiba",
    "BNB",
    "Monero",
    "XRP",
    "CRO",
    "CRO",
    "Monero",
    "ETH",
    "Monero",
    "BTC",
    "CRO",
    "Uniswap",
    "Shiba",
    "Pepe",
    "Pepe",
    "Cardano",
    "Monero",
    "Uniswap",
    "Uniswap",
    "Pepe",
    "CRO",
    "Uniswap",
    "Cardano",
    "Monero",
    "Doge",
    "BNB",
    "Monero",
    "Cardano",
    "Cardano",
    "Uniswap",
    "Monero",
    "Doge",
    "Pepe",
    "XRP",
    "Uniswap",
    "Pepe",
    "Cardano",
    "Pepe",
    "CRO",
    "Pepe",
    "XRP",
    "Shiba",
    "XRP",
    "Cardano",
    "Doge",
    "Pepe",
    "Uniswap",
    "XRP",
    "Shiba",
    "ETH",
  ];