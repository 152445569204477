import giftIcon from "../assets/images/gift_icon.png";
import dollarCoinIcon from "../assets/images/dollar_coin_icon.png";
import { MoonLoader } from "react-spinners";
import ScaleUpAnimation from "../animations/ScaleUpAnimation";
import AirdropCoinIcon from "./AirdropCoinIcon";
import ChipIcon from "./ChipIcon";

const CongratulationsPopUpContent = ({ result, reward, currency = "CC" }) => {
  if (currency !== "CC" && currency !== "USD") {
    throw new Error("Currency not supported");
  }

  return (
    <div className="flex flex-col gap-2 text-2xl items-center justify-center min-h-[35vh]">
      {(result?.isLoading ?? true) && (
        <>
          <div className="mb-4">
            <MoonLoader color="#15CF05" />
          </div>
          <div className="text-xl">Loading...</div>
        </>
      )}
      {result?.isSuccess && (
        <>
          <div className="flex flex-col items-center justify-between gap-2">
            <ScaleUpAnimation>
              {currency === "CC" ? (
                <img
                  src={giftIcon}
                  alt="Confetti icon"
                  className="h-[25vh] aspect-[176/192]"
                />
              ) : (
                <img
                  src={dollarCoinIcon}
                  alt="Dollar icon"
                  className="h-[19vh]"
                />
              )}
            </ScaleUpAnimation>
            <div className="text-2xl agbalumo-regular">
              {currency === "USD" ? "You earned" : "You won"}
            </div>
            <div className="flex flex-row gap-2 items-center mb-4">
              {currency === "USD" ? (
                <>
                  ${reward}
                  <div className="mx-2">=</div>
                  <div className="flex flex-row gap-2 items-center text-sm">
                    <ChipIcon className="w-5 h-5" /> {`${reward * 57}`}
                  </div>
                </>
              ) : (
                <>
                  <AirdropCoinIcon className={"w-7 h-7"} />
                  {reward}
                </>
              )}
            </div>
            {currency === "USD" && (
              <div className="opacity-30 text-sm text-center absolute bottom-4 mx-1">
                <br />
                Withdraw earned chips to receive money.
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CongratulationsPopUpContent;
