import { useGetReferredUsersQuery } from "../../services/official";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/time";

export const ReferredPeopleTabContent = () => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetReferredUsersQuery({ userId });

  return (
    <div className="flex flex-col items-center pt-3 w-full px-5 mb-5">
      <div className="text-2xl agbalumo-regular mb-4">Referred Friends</div>
      <div className="flex flex-col item-start w-full">
        <div className="text-xs mb-[0.5rem] opacity-35 mt-5"></div>
        {data?.referredUsers?.length == 0 && (
          <div className="text-sm opacity-35 h-full flex flex-col justify-center items-center">
            No referred friends yet
          </div>
        )}
        <div className="flex flex-col gap-2">
          {data?.referredUsers?.length > 0 && (
            <>
              <div className="text-xs mb-[0.5rem] opacity-35 flex flex-row justify-between">
                <div>Name</div>
                <div>Registration Date</div>
              </div>
              {data?.referredUsers.map((user, index) => (
                <div
                  style={{
                    backgroundColor: "rgba(66, 43, 210, 0.45)",
                  }}
                  className="h-12 w-full rounded-xl flex flex-row items-center justify-between px-3 text-sm"
                  key={index}
                >
                  <div>{user?.userName}</div>
                  <div className="opacity-30">
                    {user?.registeredAt &&
                      formatDate(Date.parse(user.registeredAt))}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
