import AirdropPointsSourceCard from "../../components/AirdropPointsSourceCard";
import BigAirdropPointsBalanceIndicatorCard from "../../components/BigAirdropsPointBalanceIndicatorCard";
import { useGetAirdropSourcesQuery } from "../../services/official";
import { useSelector } from "react-redux";

const CasinoCoinsTabContent = () => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetAirdropSourcesQuery({ userId });
  return (
    <div className="flex flex-col items-center pt-3 w-full px-5 pb-3">
      <div className="text-2xl agbalumo-regular mb-8">CasinoCoins</div>
      <BigAirdropPointsBalanceIndicatorCard />
      <div className="flex w-full items-start mt-3">
        <div className="text-xs mb-[0.5rem] opacity-35">Sources</div>
      </div>
      <div className="flex items-start w-full flex flex-col gap-3">
        <AirdropPointsSourceCard source="Completing Tasks" amount={data?.totalSocialMediaTaskRewards} />
        <AirdropPointsSourceCard source="Daily Bonus" amount={data?.totalDailyBonusRewards} />
        <AirdropPointsSourceCard source="Signing Up" amount={data?.signingUpReward} />
        <AirdropPointsSourceCard source="Referrals" amount={data?.totalReferralRewards} />
        <AirdropPointsSourceCard source="Games" amount={data?.totalSlotGameRewards} />
      </div>
    </div>
  );
};

export default CasinoCoinsTabContent;
