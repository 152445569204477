export function getTransactionLink(currency, transactionHash, network) {
    switch (currency) {
      case "TetherErc20":
        if (network === "mainnet") {
          return `https://etherscan.io/tx/${transactionHash}`;
        } else if (network === "sepolia") {
          return `https://sepolia.etherscan.io/tx/${transactionHash}`;
        }
      case "TetherTrc20":
        if (network === "mainnet") {
          return `https://tronscan.org/#/transaction/${transactionHash}`;
        } else if (network === "nile") {
          return `https://nile.tronscan.org/#/transaction/${transactionHash}`;
        }
      default:
        throw new Error("Not implemented.");
    }
  }