import rightArrowIcon from "../assets/images/right_arrow_icon.svg";
import SquareIconButton from "./SquareIconButton";

const RightArrowButton = ({ onClick, isDisabled }) => {
  return (
    <SquareIconButton
      icon={rightArrowIcon}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
};

export default RightArrowButton;
