import QRCodeStyling from "qr-code-styling";
import { useEffect, useRef } from "react";

const QrCode = ({ value }) => {
  const qrCodeRef = useRef(null);

  useEffect(() => {
    const qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      data: value,
      dotsOptions: {
        color: "#ffffff",
        type: "extra-rounded", // Makes all the lines (dots) rounded
        gradient: {
          type: "linear",
          colorStops: [
            {
              offset: 0,
              color: "rgba(245, 247, 255, 0.35)",
            },
            {
              offset: 1,
              color: "rgba(97, 123, 255, 0.35)",
            },
          ],
        },
      },

      backgroundOptions: {
        color: "transparent",
      },
    });

    if (qrCodeRef.current.firstChild) {
      qrCodeRef.current.removeChild(qrCodeRef.current.firstChild);
    }
    qrCode.append(qrCodeRef.current);
  }, [value]);

  return (
    <div
      className="flex items-center justify-center aspect-square w-min rounded-3xl p-2"
      style={{
        background: "linear-gradient(to bottom, #280EC7, #4734BD)",
        boxShadow: "0px 3px 0px #5948C6, 0px 8px 0px #3926B1",
        minWidth: "150px",
        minHeight: "150px",
      }}
    >
      <div ref={qrCodeRef} />
    </div>
  );
};

export default QrCode;
