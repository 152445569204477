import commissionBonusCardBackground from "../assets/images/commission_bonus_card_background.png";

const CommissionBonusCard = () => {
  return (
    <div
      style={{
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        aspectRatio: "354/144",
        backgroundImage: `url(${commissionBonusCardBackground})`,
      }}
      className="w-full rounded-2xl"
    >
      <div className="flex flex-grow flex-col justify-between items-start gap-2 px-5 pb-5 pt-5 h-full">
        <div className="flex flex-col gap-1 w-full justify-between h-full">
          <div className="flex flex-col w-3/5 gap-2">
            <div className="text-lg font-bold">Bonuses</div>
            <div className="text-sm opacity-75">Invite friends and get 10% commission</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionBonusCard;
