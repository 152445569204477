import { useMemo } from "react";
import { getHourDifference as getHoursPassed } from "../utils/time";
import RightArrowButton from "./RightArrowButton";

const LiveWithdrawalCard = ({ amount, username, date, transactionHash, transactionLink, index }) => {
  const hoursAgo = useMemo(() => {
    const hoursPassed = getHoursPassed(date);
    if (hoursPassed < 1) {
      return "Recently";
    }
    if (hoursPassed === 1) {
      return "1 hour ago";
    }

    return `${hoursPassed} hours ago`;
  }, [date]);
  return (
    <div>
      <div
        style={{
          background: "rgba(66, 43, 210, 0.45)",
          overflow: "hidden", // Ensures that any protruding part of the child is clipped
        }}
        className="h-14 rounded-xl flex flex-col justify-between px-3"
      >
        <div className="flex justify-between w-full pl-3 flex-grow items-center text-sm">
          <div className="gap-3 flex flex-row">
            <div>{index + 1}</div> <div>{username}</div>
          </div>
          <div className="flex flex-row items-center justify-end">
            <div className="flex-grow text-right opacity-35 pr-3">
              ${amount}
            </div>
            <RightArrowButton
              onClick={() => {
                window.open(transactionLink, "_blank");
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-between text-right text-xs mt-1 opacity-35 px-4">
        <div className="overflow-hidden whitespace-nowrap text-ellipsis w-1/2">
          {transactionHash}
        </div>
        <div>{hoursAgo}</div>
      </div>
    </div>
  );
};

export default LiveWithdrawalCard;
