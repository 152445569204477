import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import MinorWinEffect from "../components/MinorWinEffect";
import SlotMachine from "../components/SlotMachine";
import slotsGameTitle from "../assets/images/slot_machine/slots_game_title.png";
import { useAutoplay, useSpin } from "../utils/slots";
import {
  setLocked,
  setDisplayedAirDropBalance,
  setDisplayedChipBalance,
  setSpinning,
} from "../features/slotsSlice";
import { useDispatch } from "react-redux";
import AirdropEffect from "../components/AirdropEffect";
import "./SlotsScreen.css";
import ScaleUpAnimation from "../animations/ScaleUpAnimation";
import { useGetUserQuery } from "../services/official";
import slotsBackground from "../assets/images/slot_machine/background.png";
import SlotsOptions from "../components/Slots/SlotsOptions";
import leftArrowIcon from "../assets/images/slot_machine/left_arrow_icon.svg";
import SquareIconButton from "../components/Slots/SquareIconButton";
import { useNavigate } from "react-router-dom";

const SlotsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const airdropEffectRef = useRef();
  const minorWinEffectRef = useRef();
  const slotMachineRef = useRef();
  const userId = useSelector((state) => state.user.id);
  const { data: user } = useGetUserQuery(userId);

  const spin = useSpin({
    slotMachineRef,
    minorWinEffectRef,
    airdropEffectRef,
  });
  const autoplay = useAutoplay({
    airdropEffectRef,
    slotMachineRef,
    minorWinEffectRef,
  });

  useEffect(() => {
    dispatch(setLocked(false));
    dispatch(setSpinning(false));
    dispatch(setDisplayedAirDropBalance(user?.airdropPoints));
    dispatch(
      setDisplayedChipBalance(
        user?.onlyForPlayChipBalance + user?.withdrawableChipBalance
      )
    );
  }, []);

  return (
    <>
      <AirdropEffect ref={airdropEffectRef} />
      <div
        className="flex flex-col justify-between items-center h-screen w-full overflow-hidden bg-no-repeat bg-cover bg-center mt-[-4rem]"
        style={{
          backgroundImage: `url(${slotsBackground})`,
        }}
      >
        <div className="flex-grow flex flex-row items-start justify-center w-full h-full overflow-hidden z-[4]">
          <ScaleUpAnimation>
            <img
              src={slotsGameTitle}
              className="w-[97vw] mt-5 slots-title ml-1 mb-[1.5rem]"
            />

            <SlotMachine ref={slotMachineRef} />
            <div className="w-full mt-[-0.5rem]">
              <SlotsOptions onSpin={spin} onAutoplay={autoplay} />
            </div>
          </ScaleUpAnimation>
          <MinorWinEffect ref={minorWinEffectRef} />
          <div className="absolute bottom-0 left-[0.1rem] opacity-60">
            <SquareIconButton
              icon={leftArrowIcon}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SlotsScreen;
