import { useLocation } from "react-router-dom";
import HomeHeader from "./HomeHeader";

const Header = () => {
  const location = useLocation();

  switch (location.pathname) {
    case "/":
    case "/wallet":
    case "/referral":
    case "/earn":
    case "/prize":
    case "/settings":
    case "/notifications":
    case "/payout/history":
    case "/terms-of-use":
      return <HomeHeader />;
    default:
      return null;
  }
};

export default Header;
