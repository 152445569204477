const Button = ({
  text,
  isDisabled = false,
  onClick,
  icon,
  variant = "primary",
}) => {
  const variantStyling = {
    primary: "h-12 w-full px-4 text-xl font-bold rounded-lg",
    secondary: "h-[calc(2rem+3px)] px-4 text-md font-bold rounded-lg mb-[3px]",
    third: "h-8 w-full px-4 text-xs font-bold rounded-lg",
    fourth: "h-10 px-4 w-full text-lg font-bold rounded-lg",
  };

  const variantBackground = {
    primary: "linear-gradient(to bottom, #15CF05, #91F934)",
    secondary: "linear-gradient(to bottom, #15CF05, #91F934)",
    third: "linear-gradient(to bottom, #617BFF, #F5F7FF)",
    fourth: "linear-gradient(to bottom, #280EC7, #4734BD)",
  };

  const variantBoxShadow = {
    primary: "0px 5px 0px #46CC1C, inset 0px -3px 0px #C3FD70",
    secondary: "0px 3px 0px #46CC1C", // No inset shadow for secondary, if needed
    third: "0px 1px 0px #FFFFFF, 0px 4px 0px #8FA2FF", // Combine the box shadows
  };

  return (
    <div
      style={{
        filter: isDisabled ? "grayscale(1)" : "none",
      }}
    >
      <button
        className={`${variantStyling[variant]} flex flex-row items-center justify-center mb-[5px]`}
        style={{
          background: variantBackground[variant],
          boxShadow: variantBoxShadow[variant], // Use box-shadow instead of filter
        }}
        disabled={isDisabled}
        onClick={!isDisabled ? onClick : () => {}}
      >
        {icon && <img src={icon} alt="icon" className="w-4 h-4 mr-1" />}
        <div
          className="text-[#1E0F6A] overflow-hidden whitespace-nowrap text-ellipsis"
          style={{
            opacity: isDisabled ? 0.35 : 1,
          }}
        >
          {text}
        </div>
      </button>
    </div>
  );
};

export default Button;
