import TickIcon from "./TickIcon";

const Checkbox = ({ label, isChecked, onChange }) => {
  return (
    <button
      className="flex flex-row items-start w-full"
      onClick={() => {
        onChange(!isChecked);
      }}
    >
      {isChecked ? (
        <div
          className="w-5 h-5 flex items-center justify-center"
          style={{
            borderRadius: 3,
            background: "linear-gradient(to right, #15CF05, #91F934)",
          }}
        >
          <TickIcon
            size="1rem"
            color="rgba(30, 15, 106, 1)"
          />
        </div>
      ) : (
        <div
          className="w-5 h-5"
          style={{
            background:
              "linear-gradient(to bottom, rgba(248, 246, 252, 0.4), rgba(218, 215, 228, 1))",
            borderRadius: 3,
          }}
        ></div>
      )}
      <div className="ml-3 text-xs text-justify w-[85%]">{label}</div>
    </button>
  );
};

export default Checkbox;
