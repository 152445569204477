import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAirdropPopUpOpen: false,
    isRewardPopUpOpen: false,
    rewardPopUpStatus: { isOpen: false, isSuccess: false, isLoading: false, reward: null, currency: null },
    dailyRewardClaimResult: null,
    selectedWalletTabIndex: 0,
    selectedReferralTabIndex: 0
};

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setAirdropPopUpOpen(state, action) {
            state.isAirdropPopUpOpen = action.payload;
        },
        setRewardPopUpStatus(state, action) {
            state.rewardPopUpStatus = action.payload;
        },
        setSelectedWalletTabIndex(state, action) {
            state.selectedWalletTabIndex = action.payload;
        },
        setSelectedReferralTabIndex(state, action) {
            state.selectedReferralTabIndex = action.payload;
        }
    },
});

export const { setAirdropPopUpOpen, setRewardPopUpStatus, setSelectedWalletTabIndex, setSelectedReferralTabIndex } = generalSlice.actions;
export default generalSlice.reducer;
