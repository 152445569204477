import Card from "../../components/Card";
import personIcon from "../../assets/images/person_icon.svg";
import { useGetReferralStatisticsQuery } from "../../services/official";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import CommissionBonusCard from "../../components/CommissionBonusCard";

const CommissionSummaryTabContent = () => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetReferralStatisticsQuery({ userId });

  const invitedFriendsTitle = useMemo(() => {
    if (!data) {
      return "";
    }
    if (data?.totalReferredUsers === 1) {
      return "1 friend";
    }
    return `${data?.totalReferredUsers} Friends`;
  }, [data?.totalReferredUsers]);

  return (
    <div className="flex flex-col items-center pt-3 w-full px-5 mb-5">
      <div className="text-2xl agbalumo-regular mb-8">Commission Summary</div>
      <div className="mb-5 w-full">
        <CommissionBonusCard />
      </div>
      <Card
        icon={
          <img
            src={personIcon}
            alt="Person icon"
            className="w-4 h-auto opacity-30"
          />
        }
        label="General commission data"
        leftText={invitedFriendsTitle}
        rightElement={<div className="text-xs opacity-35">Commission: 10%</div>}
      />

      <div className="flex flex-col item-start gap-2 w-full">
        <div className="text-xs mb-[0.5rem] opacity-35 mt-5"></div>
        <Card
          label="Commission by time periods"
          leftText={`$${data?.totalCommissionInUsd}`}
          rightElement={<div className="text-xs opacity-35">All time</div>}
        />
        <Card
          leftText={`$${data?.commissionInUsdSinceLastPayment}`}
          rightElement={<div className="text-xs opacity-35">Since last payment</div>}
        />
        <Card
          leftText={`$${data?.commissionInUsdThisMonth}`}
          rightElement={<div className="text-xs opacity-35">This month</div>}
        />
      </div>
    </div>
  );
};

export default CommissionSummaryTabContent;
