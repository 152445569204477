import ModalBottomSheet from "./ModalBottomSheet/ModalBottomSheet";
import Button from "./Button";
import { useMemo } from "react";
import {
  useCompleteSocialMediaTaskMutation,
  useMarkSocialMediaTaskAsClickedMutation,
} from "../services/official";
import { useEffect, useState } from "react";
import CongratulationsPopUpContent from "./CongratulationsPopUpContent";
import AirdropCoinIcon from "./AirdropCoinIcon";
import plusIcon from "../assets/images/plus_icon.svg";

const SocialMediaTaskBottomSheet = ({
  icon,
  title,
  handleAction,
  actionButtonText,
  rewardInAirdropPoints,
  isOpen,
  close,
  taskId,
  moderationStartedAt,
}) => {
  const [markAsClicked] = useMarkSocialMediaTaskAsClickedMutation();
  const [complete, completeResult] = useCompleteSocialMediaTaskMutation();
  const [shouldShowError, setShouldShowError] = useState(false);

  const [moderationTime, setModerationTime] = useState(() => {
    if (moderationStartedAt === null || moderationStartedAt === undefined)
      return 0;
    return new Date() - Date.parse(moderationStartedAt);
  });

  useEffect(() => {
    if (moderationStartedAt === null || moderationStartedAt === undefined)
      return;
    setModerationTime(new Date() - Date.parse(moderationStartedAt));
    const interval = setInterval(() => {
      setModerationTime(new Date() - Date.parse(moderationStartedAt));
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [moderationStartedAt]);

  const isModerationOngoing = useMemo(
    () =>
      moderationStartedAt !== undefined &&
      moderationStartedAt !== null &&
      moderationTime < 3600000,
    [moderationTime, moderationStartedAt]
  );

  useEffect(() => {
    if (completeResult.isError) {
      setShouldShowError(true);
    }

    setTimeout(() => {
      setShouldShowError(false);
    }, 3000);
  }, [completeResult.isError]);

  const numberOfMinutesLeft = useMemo(
    () => Math.floor((3600000 - moderationTime) / 60000),
    [moderationTime]
  );

  return (
    <ModalBottomSheet isOpen={isOpen} close={close}>
      {completeResult.isSuccess && (
        <div className="mb-8">
          <CongratulationsPopUpContent
            result={completeResult}
            reward={rewardInAirdropPoints}
          />
        </div>
      )}
      {!completeResult.isSuccess && (
        <div className="flex flex-col items-center gap-4 w-[70%] pb-10">
          <img src={icon} alt={title} className="w-16 h-16" />
          <p className="text-xl">{title}</p>
          <div className="w-[40%]">
            <Button
              text={actionButtonText}
              className="mt-4"
              variant="third"
              onClick={() => {
                handleAction();
                markAsClicked(taskId);
              }}
            />
          </div>
          {isModerationOngoing && !shouldShowError && (
            <div className="text-sm text-center">
              Wait {numberOfMinutesLeft}{" "}
              {numberOfMinutesLeft > 1 ? "minutes" : "minute"} for moderation
              check to claim the prize.
            </div>
          )}
          {shouldShowError && (
            <div className="text-sm text-center text-red-500">
              {completeResult.error?.data?.message ?? "An error occurred."}
            </div>
          )}
          <div className="flex flex-row gap-2 items-center justify-center text-md my-2">
            <AirdropCoinIcon className="w-8 h-8" />
            <img src={plusIcon} className="w-4 h-4" /> {rewardInAirdropPoints}
          </div>
          <div className="w-full mb-8">
            <Button
              text={"Check"}
              isDisabled={isModerationOngoing || shouldShowError}
              onClick={() => complete(taskId)}
            />
          </div>
        </div>
      )}
    </ModalBottomSheet>
  );
};

export default SocialMediaTaskBottomSheet;
