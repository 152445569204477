import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    betSize: null,
    isLocked: false,
    isSpinning: false,
    displayedChipBalance: null,
    displayedAirDropBalance: null,
    isSoundEnabled: true,
};

const slotsSlice = createSlice({
    name: 'slots',
    initialState,
    reducers: {
        setBetSize(state, action) {
            const betSize = action.payload;
            state.betSize = betSize;
        },
        setLocked(state, action) {
            const isLocked = action.payload;
            state.isLocked = isLocked;
        },
        setSpinning(state, action) {
            const isSpinning = action.payload;
            state.isSpinning = isSpinning;
        },
        setDisplayedChipBalance(state, action) {
            const displayedChipBalance = action.payload;
            state.displayedChipBalance = displayedChipBalance;
        },
        setDisplayedAirDropBalance(state, action) {
            const displayedAirDropBalance = action.payload;
            state.displayedAirDropBalance = displayedAirDropBalance;
        },
        setSoundEnabled(state, action) {
            const isSoundEnabled = action.payload;
            state.isSoundEnabled = isSoundEnabled;
        }
    }
});

export const {
    setBetSize,
    setLocked,
    setSpinning,
    setDisplayedAirDropBalance,
    setDisplayedChipBalance,
    setSoundEnabled
} = slotsSlice.actions;
export default slotsSlice.reducer;

