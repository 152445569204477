const TextWithStroke = ({ text, strokeColor, className, style, idToPass }) => {
  return (
    <div
      style={{
        WebkitTextStroke: `1px ${strokeColor}`,
        ...style,
      }}
      className={`gang-of-three ${className}`}
      id={idToPass}
    >
      {!idToPass && text}
    </div>
  );
};

export default TextWithStroke;
