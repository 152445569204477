import DepositTaskCard from "../components/DepositTaskCard";
import InviteFriendsTaskCard from "../components/InviteFriendsTaskCard";
import SlotsSpinTaskCard from "../components/SlotsSpinTaskCard";
import SocialMediaTaskCard from "../components/SocialMediaTaskCard";
import {
  shareOnFacebook,
  shareContent,
  shareOnTelegram,
  shareOnTwitter,
  openLink,
} from "../utils/socialMedia";
import { useReflink } from "../utils/socialMedia";

const EarnScreen = () => {
  const reflink = useReflink();

  return (
    <>
      <div className="pb-32 mt-20 static">
        <div className="text-3xl mt-1 mb-5 text-center agbalumo-regular">
          Complete tasks and earn!
        </div>
        <div className="mt-6 mb-4">
          <SlotsSpinTaskCard />
          <DepositTaskCard />
        </div>
        <div className="mb-4">
          <InviteFriendsTaskCard />
        </div>
        <div className="text-xs ml-5 mt-3 mb-[-0.25rem] opacity-30">Share</div>
        <SocialMediaTaskCard
          platform="telegram"
          type="share"
          rewardInAirdropPoints={2000}
          handleAction={() =>
            shareOnTelegram(
              "Do you want to receive casino airdrops and to win some big money? Join our Telegram for exciting rewards that are coming soon!",
              reflink
            )
          }
        />

        <SocialMediaTaskCard
          platform="instagram"
          type="share"
          rewardInAirdropPoints={1000}
          handleAction={() =>
            shareContent(
              undefined,
              "🎰💸 Loving Casino Airdrop Telegram Bot! Play, win, and keep getting free airdrops! 🤑 No losses, just gains! Join the fun! #CasinoAirdrop #FreeMoney",
              reflink
            )
          }
        />
        <SocialMediaTaskCard
          platform="x"
          type="share"
          rewardInAirdropPoints={900}
          handleAction={() =>
            shareOnTwitter(
              "Big fan of the Casino Airdrop Telegram Bot! It's hands down the best game I've ever played. All I do is play and keep making money every day! The best part? You never lose money; you keep getting more free casino airdrops! 🌟 Come join the fun and start winning!",
              reflink
            )
          }
        />
        <SocialMediaTaskCard
          platform="facebook"
          type="share"
          rewardInAirdropPoints={750}
          handleAction={() => shareOnFacebook(reflink)}
        />
        <SocialMediaTaskCard
          platform="tiktok"
          type="share"
          rewardInAirdropPoints={500}
          handleAction={() =>
            shareContent(
              undefined,
              `
I just found out about FREE casino airdrops 🤑

⬆️ Tap link in bio to join Casino Telegram Bot 🎰 Free airdrops daily 💰 Big prizes dropping soon 🔥 Don't miss out bestie!

#FreeMoney #CryptoTok #CasinoAirdrop #EasyMoney #FreeAirdrop #Crypto #MakeMoneyOnline
              `,
              reflink
            )
          }
        />

        <div className="text-xs ml-5 mt-5 mb-[-0.25rem] opacity-30">Follow</div>
        <SocialMediaTaskCard
          platform="telegram"
          type="follow"
          rewardInAirdropPoints={1000}
          handleAction={() => openLink("https://t.me/officialcasinoairdrop")}
        />
        <SocialMediaTaskCard
          platform="instagram"
          type="follow"
          rewardInAirdropPoints={300}
          handleAction={() =>
            openLink("https://www.instagram.com/casino.airdrop/")
          }
        />
        <SocialMediaTaskCard
          platform="x"
          type="follow"
          rewardInAirdropPoints={300}
          handleAction={() => openLink("https://x.com/casino_airdrop")}
        />
        <SocialMediaTaskCard
          platform="tiktok"
          type="follow"
          rewardInAirdropPoints={100}
          handleAction={() => openLink("https://www.tiktok.com/@casinoairdrop")}
        />
      </div>
    </>
  );
};

export default EarnScreen;
