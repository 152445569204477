import chipIcon from "../assets/images/chip_icon.png";

const ChipIcon = ({ className }) => {
  return (
    <img
      src={chipIcon}
      alt="Chip icon"
      className={className}
      style={{
        filter: `
  drop-shadow(0px 1.86px 11px rgba(24, 154, 100, 0.55)) 
  drop-shadow(0px 1.86px 3.18px rgba(0, 255, 160, 0.17))
`,
      }}
    />
  );
};

export default ChipIcon;
