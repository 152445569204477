import { useSelector } from "react-redux";
import { useGetUserQuery } from "../services/official";

export const useUserBalance = () => {
    const { id: userId } = useSelector((state) => state.user);
    const { data: user } = useGetUserQuery(userId);
    return {
        chipBalance: user ? user?.onlyForPlayChipBalance + user?.withdrawableChipBalance : 0,
        airdropBalance: user?.airdropPoints ?? 0
    };
};

export const formatBalance = (balance) => {
    return balance?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};