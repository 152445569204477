import slotsCard from '../assets/images/slots_card.png';
import pokerCard from '../assets/images/poker_card.png';
import rouletteCard from '../assets/images/roulette_card.png';
import diceCard from '../assets/images/dice_card.png';
import notificationIcon from "../assets/images/notification_icon.png";
import settingsIcon from "../assets/images/settings_icon.png";
import chipIcon from "../assets/images/chip_icon.png";
import airdropCoinIcon from "../assets/images/airdrop_coin_icon.png";
import closeIcon from "../assets/images/close_icon.svg";
import instagramLogo from "../assets/images/instagram_logo.svg";
import facebookLogo from "../assets/images/facebook_logo.svg";
import telegramLogo from "../assets/images/telegram_logo.svg";
import tikTokLogo from "../assets/images/tiktok_logo.svg";
import xLogo from "../assets/images/x_logo.svg";
import airdropIcon from "../assets/images/airdrop_icon.png";
import copyToClipboardIcon from "../assets/images/copy_to_clipboard_icon.svg";
import arrowDownIcon from "../assets/images/arrow_down_icon.svg";
import reelBorder from "../assets/images/reel_border.png";
import reelBackground from "../assets/images/reel_background.png";
import slotsScoreBackground from "../assets/images/slots_score_background.png";
import selectedIconBackground from "../assets/images/selected_icon_background.svg";
import btcSymbol from "../assets/images/slot_machine_symbols/bitcoin.png";
import ethSymbol from "../assets/images/slot_machine_symbols/eth.png";
import bnbSymbol from "../assets/images/slot_machine_symbols/bnb.png";
import dogeSymbol from "../assets/images/slot_machine_symbols/doge.png";
import xrpSymbol from "../assets/images/slot_machine_symbols/xrp.png";
import cardanoSymbol from "../assets/images/slot_machine_symbols/cardano.png";
import shibaSymbol from "../assets/images/slot_machine_symbols/shiba.png";
import croSymbol from "../assets/images/slot_machine_symbols/cronos.png";
import moneroSymbol from "../assets/images/slot_machine_symbols/monero.png";
import pepeSymbol from "../assets/images/slot_machine_symbols/pepe.png";
import uniswapSymbol from "../assets/images/slot_machine_symbols/uniswap.png";
import rightArrowIcon from "../assets/images/right_arrow_icon.svg";
import walletIcon from "../assets/images/wallet_icon.svg";
import selectedWalletIcon from "../assets/images/selected_wallet_icon.svg";
import homeIcon from "../assets/images/home_icon.svg";
import selectedHomeIcon from "../assets/images/selected_home_icon.svg";
import earnIcon from "../assets/images/earn_icon.svg";
import selectedEarnIcon from "../assets/images/selected_earn_icon.svg";
import referralIcon from "../assets/images/referral_icon.svg";
import giftIcon from "../assets/images/gift_icon.png";
import selectedReferralIcon from "../assets/images/selected_referral_icon.svg";
import prizeIcon from "../assets/images/prize_icon.svg";
import selectedPrizeIcon from "../assets/images/selected_prize_icon.svg";
import leftPopUpConfetti from "../assets/images/left_pop_up_confetti.png";
import rightPopUpConfetti from "../assets/images/right_pop_up_confetti.png";
import commissionBonusCardBackground from "../assets/images/commission_bonus_card_background.png";
import referralBalanceCardBackground from "../assets/images/referral_balance_card_background.png";
import reflinkCardBackground from "../assets/images/reflink_card_background.png";
import bitcoinLogo from "../assets/images/bitcoin_logo.png";
import ethereumLogo from "../assets/images/ethereum_logo.png";
import tetherLogo from "../assets/images/tether_logo.png";
import trcTetherLogo from "../assets/images/trc_tether_logo.png";
import tronLogo from "../assets/images/tron_logo.png";
import personIcon from "../assets/images/person_icon.svg";
import dailyBonusBackground from "../assets/images/daily_bonus_background.png";
import linkIcon from "../assets/images/link_icon.svg";
import helpIcon from "../assets/images/help_icon.png";
import fancyRightArrowIcon from "../assets/images/fancy_right_arrow_icon.svg";
import slotsMachineBackground from "../assets/images/slot_machine/slot_machine_background.png";
import textButtonBackground from "../assets/images/slot_machine/text_button_background.png";
import squareIconButtonBackground from "../assets/images/slot_machine/square_icon_button_background.png";
import spinBarBackground from "../assets/images/slot_machine/spin_bar_background.png";
import spinButton from "../assets/images/slot_machine/spin_button.png";
import balanceIndicatorBackground from "../assets/images/slot_machine/balance_indicator_background.png";
import soundEnabledIcon from "../assets/images/slot_machine/sound_enabled_icon.png";
import soundDisabledIcon from "../assets/images/slot_machine/sound_disabled_icon.png";
import slotsGameTitle from "../assets/images/slot_machine/slots_game_title.png";
import leftArrowIcon from "../assets/images/slot_machine/left_arrow_icon.svg";
import slotsPlusIcon from "../assets/images/slot_machine/plus_icon.png";
import slotsMinusIcon from "../assets/images/slot_machine/minus_icon.png";
import plusIcon from "../assets/images/plus_icon.svg";
import airdropConfettiIcon from "../assets/images/airdrop_confetti_icon.png";
import greenCircle from "../assets/images/green_circle.svg";
import grayCircle from "../assets/images/gray_circle.svg";
import crownIcon from "../assets/images/crown_icon.svg";
import firstPlaceBadge from "../assets/images/first_place_badge.png";
import secondPlaceBadge from "../assets/images/second_place_badge.png";
import thirdPlaceBadge from "../assets/images/third_place_badge.png";
import withdrawalArrowDownIcon from "../assets/images/withdrawal_arrow_down_icon.svg";
import depositArrowUpIcon from "../assets/images/deposit_arrow_up_icon.svg";
import historyIcon from "../assets/images/history_icon.svg";
import slotMachineIcon from "../assets/images/slot_machine_icon.png";
import dollarIcon from "../assets/images/dollar_icon.png";
import gleamContestBanner from "../assets/images/gleam_contest_banner.jpeg";

export const preloadImages = (onProgress) => {
  const images = getImages();
  const total = images.length;
  let loaded = 0;

  const promises = images.map((src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loaded += 1;
        if (onProgress) {
          onProgress(loaded);
        }
        resolve();
      };
      img.onerror = () => {
        console.error(`Failed to load image: ${src}`);
        loaded += 1;
        if (onProgress) {
          onProgress(loaded);
        }
        resolve();
      };
    });
  });

  return { total, promise: Promise.all(promises) };
};

export const getImages = () => {
  return [
    dollarIcon,
    slotMachineIcon,
    withdrawalArrowDownIcon,
    depositArrowUpIcon,
    historyIcon,
    firstPlaceBadge,
    secondPlaceBadge,
    thirdPlaceBadge,
    gleamContestBanner,
    crownIcon,
    greenCircle,
    grayCircle,
    airdropConfettiIcon,
    slotsPlusIcon,
    slotsMinusIcon,
    plusIcon,
    leftArrowIcon,
    slotsGameTitle,
    soundDisabledIcon,
    soundEnabledIcon,
    slotsMachineBackground,
    textButtonBackground,
    squareIconButtonBackground,
    spinBarBackground,
    spinButton,
    balanceIndicatorBackground,
    helpIcon,
    fancyRightArrowIcon,
    commissionBonusCardBackground,
    referralBalanceCardBackground,
    reflinkCardBackground,
    dailyBonusBackground,
    personIcon,
    linkIcon,
    bitcoinLogo,
    ethereumLogo,
    tetherLogo,
    trcTetherLogo,
    tronLogo,
    giftIcon,
    leftPopUpConfetti,
    rightPopUpConfetti,
    notificationIcon,
    settingsIcon,
    walletIcon,
    selectedWalletIcon,
    selectedHomeIcon,
    earnIcon,
    selectedEarnIcon,
    referralIcon,
    selectedReferralIcon,
    prizeIcon,
    selectedPrizeIcon,
    rightArrowIcon,
    btcSymbol,
    ethSymbol,
    bnbSymbol,
    dogeSymbol,
    xrpSymbol,
    cardanoSymbol,
    shibaSymbol,
    croSymbol,
    moneroSymbol,
    pepeSymbol,
    uniswapSymbol,
    selectedIconBackground,
    reelBorder,
    reelBackground,
    slotsScoreBackground,
    arrowDownIcon,
    copyToClipboardIcon,
    airdropIcon,
    instagramLogo,
    facebookLogo,
    telegramLogo,
    tikTokLogo,
    xLogo,
    closeIcon,
    chipIcon,
    airdropCoinIcon,
    homeIcon,
    slotsCard,
    pokerCard,
    rouletteCard,
    diceCard,
    // Add more imported images here
  ];
};

export default getImages;
