import greenCircle from "../assets/images/green_circle.svg";
import grayCircle from "../assets/images/gray_circle.svg";
export const DailyBonusProgressTracker = ({ currentDay }) => {
  const days = [];
  for (let i = 1; i <= 6; i++) {
    days.push(i);
  }
  return (
    <div className="flex flex-row items-center justify-center">
      {days.map((day) => (
        <div className="flex flex-row items-center justify-center">
          <DailyBonusProgressTrackerDayNumber
            dayNumber={day}
            currentDay={currentDay}
          />
          {day < 6 && (
            <div
              className="mx-[1vw]"
              style={{
                background:
                  day + 1 <= currentDay
                    ? "linear-gradient(to bottom, #5EBF2D, #6BB949)"
                    : "#454A68",
                height: "5px",
                width: "6vw",
              }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};
const DailyBonusProgressTrackerDayNumber = ({ dayNumber, currentDay }) => {
  return (
    <div className="flex items-center justify-center">
      <img
        src={greenCircle}
        alt="Green circle"
        className="absolute w-[17.6vw] h-auto"
        style={dayNumber > currentDay ? { display: "none" } : {}}
      />
      <img
        src={grayCircle}
        alt="Gray circle"
        className="absolute w-[9.5vw] h-auto"
        style={dayNumber <= currentDay ? { display: "none" } : {}}
      />
      <div
        className="rounded-full p-2 w-[6.5vw] h-[6.5vw] text-md font-bold text-center flex items-center justify-center"
        style={{
          background:
            dayNumber <= currentDay
              ? "radial-gradient(circle, #15CF05 26%, #C3FD70 95%)"
              : "radial-gradient(circle, #454A68 26%, #8B8E9E 95%)",
          filter:
            dayNumber <= currentDay
              ? "drop-shadow(0px 2.8px 0px #3A9F1B)"
              : "drop-shadow(0px 2.8px 0px #545977)",
          transform: "translateY(-1.4px)",
          boxShadow:
            dayNumber <= currentDay
              ? "inset 0px -0.93px 0px #C3FD70"
              : "inset 0px -0.93px 0px #9499B5",
          color:
            dayNumber <= currentDay ? "#1E0F6A" : "rgba(255, 255, 255, 0.35)",
        }}
      >
        {dayNumber}
      </div>
    </div>
  );
};
