import { MoonLoader } from "react-spinners";
import transactionSuccessIcon from "../../assets/images/transaction_succes_icon.svg";
import transactionFailureIcon from "../../assets/images/transaction_failure_icon.svg";
import "../ModalPopUp/ModalPopUp.css";
import { ImageButton } from "../ImageButton";
import closeIcon from "../../assets/images/close_icon.svg";
import "./WithdrawalStatusPopUp.css";

const WithdrawalStatusPopUp = ({ children, isOpen, result, onClose }) => {
  function getTransactionStatusMessage() {
    switch (result?.data?.status) {
      case "Completed":
        return "Your funds have been sent successfully.";
      case "WaitingForApproval":
        return "Your withdrawal is now waiting for manual verification.";
      default:
        throw new Error("Unknown transaction status");
    }
  }

  return (
    <div className="relative">
      <div
        style={
          isOpen
            ? {
                transition: "filter 0.5s ease-in-out", // Transition for the blur effect
                filter: "blur(3px)",
              }
            : {}
        }
      >
        {children}
      </div>
      {isOpen && (
        <div className="absolute inset-0 m-auto rounded-xl flex flex-col justify-center items-center text-xl text-center min-h-[10rem] max-w-max bg-[#221487] min-w-[90%] fade-in-scale px-3">
          <div className="absolute right-0 pt-4 pr-4 top-0">
            <ImageButton
              imageSrc={closeIcon}
              alt="Close icon"
              onClick={onClose}
              className="w-4 h-4"
            />
          </div>
          {result?.isLoading && (
            <>
              <MoonLoader color="#15CF05" />
              <div className="mt-5 text-sm opacity-30">Loading...</div>
            </>
          )}
          {result?.isError && (
            <>
              <img
                src={transactionFailureIcon}
                alt="Transaction failure icon"
                className="w-28 mb-3"
              />
              <div className="mt-2 text-sm opacity-30">
                {result?.error?.data.message}
              </div>
            </>
          )}
          {result?.isSuccess && (
            <>
              <img
                src={transactionSuccessIcon}
                alt="Transaction success icon"
                className="w-28 mb-3"
              />
              <div className="mt-2 text-sm opacity-30">
                {getTransactionStatusMessage()}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default WithdrawalStatusPopUp;
