import * as airdropEffectAnimation from "../assets/animations/airdrop_effect.json";
import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import Lottie from "react-lottie";

const AirdropEffect = forwardRef(({}, ref) => {
  const [isAirdropWinEffectPlaying, setMinorWinEffectPlaying] = useState(false);
  const playPromiseResolveRef = useRef(null); // To store the resolve function for the promise

  useImperativeHandle(ref, () => ({
    play: () => {
      if (isAirdropWinEffectPlaying) return;
      setMinorWinEffectPlaying(true);

      return new Promise((resolve) => {
        playPromiseResolveRef.current = resolve;
      });
    },
    isPlaying: () => {
      return isAirdropWinEffectPlaying;
    },
  }));

  const minorWinEffectAnimationOptions = {
    loop: false,
    autoplay: false,
    animationData: airdropEffectAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen z-[9998]"
      style={!isAirdropWinEffectPlaying ? { display: "none" } : {}}
    >
      <div className="relative w-full h-full z-[1]">
        <Lottie
          isClickToPauseDisabled={true}
          options={minorWinEffectAnimationOptions}
          isStopped={!isAirdropWinEffectPlaying}
          isPaused={!isAirdropWinEffectPlaying}
          eventListeners={[
            {
              eventName: "complete",
              callback: () => {
                setMinorWinEffectPlaying(false);
                if (playPromiseResolveRef.current) {
                  playPromiseResolveRef.current();
                  playPromiseResolveRef.current = null;
                }
              },
            },
          ]}
        />
      </div>
    </div>
  );
});

export default AirdropEffect;
