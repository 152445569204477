const Switch = ({ text, isEnabled, onClick, icon }) => {
  const circlePosition = isEnabled ? "justify-end" : "justify-start";
  const backgroundDependentStyle = "gradient-border h-14 text-sm p-3";

  return (
    <button
      onClick={onClick}
      className={`flex flex-row items-center justify-between ${backgroundDependentStyle} w-full rounded-2xl border-2 px-4`}
      style={{
        backgroundColor: "rgba(33, 18, 123, 0.45)",
        borderColor: "rgba(66, 43, 210, 0.45)",
      }}
    >
      <div className="flex flex-row items-center gap-3 text-sm">
        {icon}
        <div className="flex flex-row">{text}</div>
      </div>
      <div
        style={{
          background: !isEnabled
            ? "linear-gradient(to bottom, rgba(248, 246, 252, 0.4), rgba(218, 215, 228, 1))"
            : "linear-gradient(to right, #15CF05, #91F934)",
        }}
        className={`w-12 h-6 p-0.5 rounded-xl flex items-center ${circlePosition}`}
      >
        <div
          className="rounded-full h-5 w-5 bg-[#FFFEEE]"
          style={{ boxShadow: "0px 0px 4px #367715" }}
        ></div>
      </div>
    </button>
  );
};

export default Switch;
