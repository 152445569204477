import TermsAndConditions from "../components/TermsAndConditionsText";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { useState, useMemo } from "react";

const TermsOfUseScreen = ({ onTermsAccepted, hasTermsBeenAccepted }) => {
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [isForEntertainmentOnly, setIsForEntertainmentOnly] = useState(false);


  return (
    <div className={`${hasTermsBeenAccepted ? "pt-20 pb-24" : "pb-56 pt-8"}`}>
      <TermsAndConditions />
      <div
        className="fixed bottom-0 h-fit w-full p-3 flex flex-col gap-5 pt-5 pb-8 shadow-lg shadow-gray-900"
        style={{
          backgroundColor: "rgb(34, 20, 135)",
          display:
            hasTermsBeenAccepted ? "none" : "flex",
        }}
      >
        <Checkbox
          label="I hereby accept the Terms and Conditions."
          onChange={() => {
            setAreTermsAccepted(!areTermsAccepted);
          }}
          isChecked={areTermsAccepted}
        />
        <Checkbox
          label="I hereby declare that I am using Airdrop Casino for entertainment purposes only and have familiarized myself with the risks including the possibility of losing all my funds."
          onChange={() => {
            setIsForEntertainmentOnly(!isForEntertainmentOnly);
          }}
          isChecked={isForEntertainmentOnly}
        />
        <Button
          text="Let's have some fun!"
          isDisabled={!areTermsAccepted || !isForEntertainmentOnly}
          onClick={onTermsAccepted}
        />
      </div>
    </div>
  );
};

export default TermsOfUseScreen;
