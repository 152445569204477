const Tab = ({ title, isSelected, onClick }) => {
  const background = "linear-gradient(to bottom, #280EC7, #4734BD)";
  const boxShadow = `0px 2px 0px #6352CE, 0px 6px 0px #3720C3`;

  return (
    <button
      onClick={onClick}
      className={
        "flex flex-row h-12 w-full justify-center items-center rounded-xl mb-[6px]"
      }
      style={!isSelected ? {} : { background, boxShadow }}
    >
      <div
        className={`font-medium text-sm self-center text-center gradient-text ${
          !isSelected ? "opacity-35" : ""
        }`}
      >
        {title}
      </div>
    </button>
  );
};

export default Tab;
