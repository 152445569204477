import { useMemo } from "react";

const ProgressBar = ({ progress }) => {
  const sanitizedProgress = useMemo(() => {
    if (isNaN(progress) || progress < 0) return 0;
    if (progress > 1) return 1;
    return progress;
  })
  
  return (
    <div
      className="flex items-center w-full h-2 py-[0.2px] px-[0.1px]"
      style={{
        backgroundColor: "rgba(33, 18, 124, 0.45)",
        borderColor: "rgba(66, 43, 210, 0.75)",
        borderWidth: "2px",
        borderRadius: "3px",
      }}
    >
      <div
        style={{
          backgroundColor: "#CCB900",
          width: `${sanitizedProgress * 100}%`,
          display: sanitizedProgress === 0 ? "none" : "block",
        }}
        className="flex h-full rounded-2xl"
      ></div>
    </div>
  );
};

export default ProgressBar;
