export function getHourDifference(pastDate) {
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - pastDate;
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return Math.floor(differenceInHours);
}

export function formatDate(date) {
    return new Intl.DateTimeFormat("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    }).format(date);
}