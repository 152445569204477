import TabBar from "../../components/TabBar";
import ReferralBalanceCard from "../../components/ReferralBalanceCard";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedWalletTabIndex } from "../../features/generalSlice";
import WithdrawalTabContent from "./WithdrawalTabContent";
import DepositTabContent from "./DepositTabContent";
import HistoryTabContent from "./HistoryTabContent";
import withdrawalArrowDownIcon from "../../assets/images/withdrawal_arrow_down_icon.svg";
import depositArrowUpIcon from "../../assets/images/deposit_arrow_up_icon.svg";
import historyIcon from "../../assets/images/history_icon.svg";

const WalletScreen = () => {
  const dispatch = useDispatch();
  const { selectedWalletTabIndex } = useSelector((state) => state.general);
  return (
    <>
      <div className="flex flex-col px-5 gap-3 mt-[19.8vw] h-fit pb-32">
        <ReferralBalanceCard />
        <TabBar
          leftTitle={
            <div className="flex flex-row items-center gap-0.5">
              <img
                src={depositArrowUpIcon}
                alt="Deposit"
                className="w-4 h-auto pt-[0.2rem]"
              />
              Deposit
            </div>
          }
          middleTitle={
            <div className="flex flex-row items-center gap-0.5">
              <img
                src={withdrawalArrowDownIcon}
                alt="Withdrawal"
                className="w-4 h-auto pt-[0.2rem]"
              />
              Withdraw
            </div>
          }
          rightTitle={
            <div className="flex flex-row items-center gap-0.5">
              <img src={historyIcon} alt="History" className="w-4 h-auto" />
              History
            </div>
          }
          selectedTabIndex={selectedWalletTabIndex}
          setSelectedTabIndex={(i) => dispatch(setSelectedWalletTabIndex(i))}
          leftTabContent={<DepositTabContent />}
          middleTabContent={<WithdrawalTabContent />}
          rightTabContent={<HistoryTabContent />}
        />
      </div>
    </>
  );
};

export default WalletScreen;
