const SquareIconButton = ({ onClick, isDisabled, icon }) => {
  return (
    <div style={isDisabled ? { filter: "grayscale(1)" } : {}}>
      <button
        disabled={isDisabled}
        onClick={onClick}
        style={{
          background: "linear-gradient(to bottom, #15CF05, #91F934)",
          filter: "drop-shadow(0px 2.8px 0px #46CC1C)",
          boxShadow: "inset 0px -0.93px 0px #C3FD70",
        }}
        className=" rounded-lg p-2 aspect-square mb-[2.8px]"
      >
        <img src={icon} alt="Right arrow icon" className="w-3.5 h-3.5" />
      </button>
    </div>
  );
};

export default SquareIconButton;
