import TaskCard from "./TaskCard";
import slotMachineIcon from "../assets/images/slot_machine_icon.png";
import { useGetGleamCodeRewardsQuery } from "../services/official";
import { useMemo } from "react";

const SlotsSpinTaskCard = ({}) => {
  const { data } = useGetGleamCodeRewardsQuery();
  console.log(data);

  const threeSpinsReward = useMemo(() => {
    const rewards = data?.gleamCodeRewards;
    return rewards?.find((reward) => reward.type === "ThreeSpins");
  }, [data]);

  const usedThreeSpinCodeCount = useMemo(() => {
    return data?.codeStatistics?.usedThreeSpinCodeCount;
  }, [data]);

  const totalSpinCodeCount = useMemo(() => {
    return data?.codeStatistics?.totalThreeSpinCodeCount;
  }, [data]);

  return (
    <div>
      <TaskCard
        link="https://gleam.io/LK8qr/-win-5000-in-casino-coins-in-our-exclusive-airdrop-"
        isRewardInCC={false}
        icon={slotMachineIcon}
        title={"Spin the slots!"}
        reward={"Reveal Code"}
        tooltipId={"slots-task-tooltip"}
        secretCode={threeSpinsReward?.code}
        progress={{
          unitsCompleted: Math.min(data?.currentNumberOfSpins, 4),
          goal: data?.requiredNumberOfSpins,
        }}
        isCompleted={false}
        buttonText={true && "Show code"}
        onButtonClick={() => {}}
        iconClassName={"w-6 opacity-30"}
      />
      <div className="text-xs opacity-30 mr-5 mt-1 text-right">
        Codes available: {totalSpinCodeCount - usedThreeSpinCodeCount}/
        {totalSpinCodeCount}
      </div>
    </div>
  );
};

export default SlotsSpinTaskCard;
