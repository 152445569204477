import PulsatingAnimation from "../animations/PulsatingAnimation";
import giftIcon from "../assets/images/gift_icon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  useClaimRewardMutation,
  useGetUnclaimedRewardsQuery,
} from "../services/official";
import { useState, useMemo, useCallback, useEffect } from "react";
import { setRewardPopUpStatus } from "../features/generalSlice";
import RightArrowButton from "./RightArrowButton";

const DailyBonusButton = ({}) => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector((state) => state.user);
  const { data: unclaimedRewards } = useGetUnclaimedRewardsQuery({ userId });
  const [rewardAmount, setRewardAmount] = useState(null);

  const [claimReward, result] = useClaimRewardMutation();
  const isBonusAvailable = useMemo(() => {
    return (
      unclaimedRewards?.unclaimedRewards?.some(
        (reward) => reward?.type === "DailyLogin"
      ) ?? false
    );
  }, [unclaimedRewards]);

  const onClaimReward = useCallback(async () => {
    const oldestUnclaimedReward = unclaimedRewards?.unclaimedRewards
      ?.filter((reward) => reward?.type === "DailyLogin")
      ?.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))[0];
    setRewardAmount(oldestUnclaimedReward?.rewardAmount);
    claimReward({
      rewardId: oldestUnclaimedReward?.id,
    });
  }, [unclaimedRewards, claimReward]);

  useEffect(() => {
    if (result.isLoading || result.isSuccess)
      dispatch(
        setRewardPopUpStatus({
          isOpen: true,
          isLoading: result.isLoading,
          isSuccess: result.isSuccess,
          reward: rewardAmount,
          currency: "CC",
        })
      );
  }, [result]);

  return (
    <button
      className="flex flex-row justify-start items-center rounded-lg border-2 bg-[#21127B] h-[calc(2.2rem+5px)]"
      style={{
        borderColor: "rgba(66, 43, 210, 0.75)",
      }}
      onClick={onClaimReward}
      disabled={!isBonusAvailable}
    >
      <div className="flex flex-row items-center justify-center">
        <PulsatingAnimation isPulsating={isBonusAvailable}>
          <img
            src={giftIcon}
            alt="Gift icon"
            className="max-w-[11vw] h-auto mx-1"
            style={{
              filter: `
        drop-shadow(0px 4px 4px rgba(7, 3, 71, 0.6)) 
      `,
            }}
          />
        </PulsatingAnimation>
        <div
          className="h-5 rounded-lg bg-[#21127B]"
          style={{
            borderColor: "rgba(66, 43, 210, 0.75)",
            borderWidth: "0.93px",
            width: "2px",
          }}
        ></div>
      </div>

      <div className="flex flex-row flex-grow items-center justify-center min-w-[16vw]  ml-3 text-xs font-bold gap-2 text-nowrap aspect-[33/31]">
        Daily Bonus
        <div
          style={{ filter: !isBonusAvailable ? "grayscale(1)" : "" }}
          className="ml-1.5 mr-0.5"
        >
          <RightArrowButton />
        </div>
      </div>
    </button>
  );
};

export default DailyBonusButton;
