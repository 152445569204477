import airdropCoinIcon from "../assets/images/airdrop_coin_icon.png";
import { useGetUserQuery } from "../services/official";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import Card from "./Card";
import AirdropCoinIcon from "./AirdropCoinIcon";

const BigAirdropPointsBalanceIndicatorCard = ({}) => {
  const { id } = useSelector((state) => state.user);
  const { data: user } = useGetUserQuery(id, { keepPreviousData: true });

  const balance = useMemo(() => {
    return user?.airdropPoints ?? 0;
  }, [user]);

  return (
    <Card
      label="Your CasinoCoins balance"
      icon={
        <img
          src={airdropCoinIcon}
          alt="Airdrop coin icon"
          className="w-6 h-auto"
        />
      }
      leftText={balance}
    />
  );
};

export default BigAirdropPointsBalanceIndicatorCard;
