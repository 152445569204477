import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    address: '',
    amount: '',
};

const withdrawalSlice = createSlice({
    name: 'withdrawal',
    initialState,
    reducers: {
        setAddress(state, action) {
            state.address = action.payload;
        },
        setAmount(state, action) {
            state.amount = action.payload;
        },
    },
});

export const { setAddress, setAmount } = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
