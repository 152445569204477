import React from "react";
import "./TermsAndConditionsText.css";

const TermsAndConditions = () => {
  return (
    <div
      className="px-8 pb-8 max-w-4xl mx-auto text-justify text-sm user-select-none"
      onCopy={(e) => e.preventDefault()}
      onCut={(e) => e.preventDefault()}
      onContextMenu={(e) => e.preventDefault()}
    >
      {" "}
      <h1 className="text-2xl font-bold text-center mb-8">
        Airdrop Casino Terms and Conditions
      </h1>
      <section>
        <p className="font-bold text-md">DISCLAIMER</p>
        <p>
          YOU ARE ONLY ABLE TO VIEW OR USE THE AIRDROP CASINO TELEGRAM APP, IF
          YOU ACCEPT ALL OF THESE TERMS AND CONDITIONS. BY USING THE
          APPLICATION:
          <br />
          <span className="block ml-4">
            (A) YOU ARE CONFIRMING THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO
            BE BOUND BY ALL OF THESE TERMS AND CONDITIONS;
          </span>
          <br />
          <span className="block ml-4">
            (B) YOU AGREE TO RESPECT THE RULES OF FAIR-PLAY;
          </span>
          <br />
          <span className="block ml-4">
            (C) YOU USE THE CASINO FOR ENTERTAINMENT PURPOSES ONLY;
          </span>
          <br />
          <span className="block ml-4">
            (D) YOU UNDERSTAND THAT IN THE CASINO YOU CAN LOSE ALL YOUR FUNDS.
          </span>
          <br />
          IF YOU DO NOT AGREE AND/OR ACCEPT ALL OF THESE TERMS AND CONDITIONS,
          THEN YOU MUST NOT USE THE APPLICATION AND YOU MUST DISCONTINUE USE
          IMMEDIATELY.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">1. GENERAL</h2>
        <p>
          <span className="font-semibold">1.1.</span> The website{" "}
          <a
            href="https://airdropcasino.com/"
            className="text-blue-500 underline"
          >
            https://airdropcasino.com/
          </a>{" "}
          as a website and a Telegram application (“Casino”, “Website”,
          “Company”, “We” or “Our”) is owned and operated by Airdrop Casino
          company.
        </p>
        <p>
          <span className="font-semibold">1.2.</span> Before using our website,
          please read these Terms and Conditions carefully. By registering a
          Player Account with the Website, you agree and confirm your consent to
          the Terms and Conditions stipulated below. You also may be redirected
          to the third-party websites and then minting/bridging/purchasing (or
          similar) the Crypto Assets as a part of the blockchain network,
          establish a relationship on a basis of other terms and conditions or
          terms and conditions of service provision specific to a particular
          blockchain network, including especially, but not only, information
          and documents available on https://telegram.org/ website or others to
          which the Website is currently redirecting.
        </p>
        <p>
          <span className="font-semibold">1.3.</span> The Player acknowledges
          and accepts that the performance of any activity on the Crypto Asset
          does not refer to any transaction or conclusion of any agreement
          directly with the Casino.
        </p>
        <p>
          <span className="font-semibold">1.4.</span> Casino may publish
          additional policies related to its services and Website. The Player
          shall agree and comply with these policies to use the services and
          Website.
        </p>
        <p>
          <span className="font-semibold">1.5.</span> Casino does not warrant
          that its Website and/or services via it will be accessible for Player
          at the times or locations chosen by the Player, are error-free or free
          of viruses or other harmful components, as well as Casino does not
          warrant that its Website or/and services via it will suit the Player’s
          purpose of use.
        </p>
        <p>
          <span className="font-semibold">1.6.</span> If you do not accept these
          Terms and Conditions, you are not allowed to use the services of the
          Casino.
        </p>
        <p>
          <span className="font-semibold">1.7.</span> The Casino reserves the
          right to unilaterally change the Terms and Conditions when such need
          occurs with notification of the Player about it, or without it. We do
          recommend all Players to visit this page regularly and check for
          possible updates.
        </p>
        <p>
          <span className="font-semibold">1.8.</span> The main language of the
          Casino website is English, and any translation errors are interpreted
          in favor of the Casino.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">
          2. LIMITATIONS OF USE OF THE CASINO
        </h2>
        <p>
          <span className="font-semibold">2.1.</span> The Casino accepts players
          only from those countries and geographic regions where online gambling
          is allowed by law. It is the player’s sole responsibility to inquire
          about the existing gambling laws and regulations of the given
          jurisdiction before placing bets on the website.
        </p>
        <p>
          <span className="font-semibold">2.2.</span> The Casino accepts
          strictly adult players (the minimum age is 18) and players who have
          reached the age specified by the jurisdiction of player’s place of
          residence as eligible for online gaming.
        </p>
        <p>
          <span className="font-semibold">2.3.</span> The Casino reserves the
          right to ask for proof of age from the player and limit access to the
          Website or suspend the Player Account to those players who fail to
          meet this requirement.
        </p>
        <p>
          <span className="font-semibold">2.4.</span> Players from the following
          countries and their territories (“the Restricted Countries”) are not
          allowed to deposit and play real money games: Afghanistan, Åland
          Islands, Albania, Algeria, American Samoa, Andorra, Angola, Anguilla,
          Antarctica, Antigua and Barbuda, Armenia, Aruba, Azerbaijan, Bahamas,
          Bangladesh, Barbados, Belarus, Belgium, Belize, Benin, Bermuda,
          Bhutan, Bolivia, Bonaire, Sint Eustatius and Saba, Bosnia and
          Herzegovina, Botswana, Bouvet Island, British Indian Ocean Territory,
          Brunei Darussalam, Burkina Faso, Burundi, Cabo Verde, Cambodia,
          Cameroon, Cayman Islands, Central African Republic , Chad, China,
          Christmas Island, Cocos (Keeling) Islands, Colombia, Comoros, Congo,
          Congo (Democratic Republic), Cook Islands, Costa Rica, Côte d'Ivoire,
          Cuba, Curaçao, Cyprus, Czech Republic, Djibouti, Dominica, Dominican
          Republic, Ecuador, El Salvador, Equatorial Guinea, Eritrea, Estonia,
          Eswatini, Ethiopia, Falkland Islands , Faroe Islands, Fiji, France,
          French Guiana , French Polynesia, Gabon, Gambia, Georgia, Ghana ,
          Gibraltar, Greece, Greenland, Grenada, Guadeloupe, Guam, Guatemala,
          Guernsey, Guinea, Guinea -Bissau, Guyana, Haiti, Heard Island and
          McDonald Islands, Holy See (Vatican City), Honduras, Hong Kong,
          Hungary, Indonesia, Iraq, Islamic Republic of Iran, Isle of Man,
          Israel, Jamaica, Jersey, Kenya , Kiribati, Korea (Democratic People's
          Republic), Korea (Republic), Kyrgyzstan, Lao People's Democratic
          Republic, Latvia, Liberia, Libya, Liechtenstein, Lithuania, Macao,
          Madagascar, Malawi, Malaysia, Maldives, Mali , Marshall Islands,
          Martinique, Mauritania, Mauritius, Mayotte, Micronesia , Moldova,
          Monaco, Mongolia, Montenegro, Montserrat, Mozambique, Myanmar,
          Namibia, Nauru, Nepal, Netherlands, New Caledonia, Nicaragua, Niger,
          Niue, Norfolk Island, Northern Mariana Islands, Pakistan, Palau,
          Palestine, Panama, Papua New Guinea, Paraguay, Pitcairn, Poland,
          Portugal, Puerto Rico, Republic of North Macedonia, Réunion, Romania,
          Rwanda, Saint Barthélemy, Saint Helena, Ascension and Tristan da
          Cunha, Saint Kitts and Nevis, Saint Lucia, Saint Martin, Saint Pierre
          and Miquelon, Samoa, San Marino, Sao Tome and Principe, Senegal,
          Serbia, Seychelles, Sierra Leone, Singapore, Sint Maarten, Solomon
          Islands, Somalia, South Georgia and the South Sandwich Islands, South
          Sudan, Spain, Sri Lanka, Sudan, Suriname, Svalbard and Jan Mayen,
          Syrian Arab Republic, Taiwan, Tajikistan , Tanzania, Timor-Leste,
          Togo, Tokelau, Tonga, Trinidad and Tobago, Turkmenistan, Turks and
          Caicos Islands, Tuvalu, Uganda, Ukraine, United Kingdom of Great
          Britain and Northern Ireland, United States Minor Outlying Islands,
          United States of America, Uruguay, Vanuatu, Venezuela, Vietnam, Virgin
          Islands (British), Virgin Islands (U.S.), Wallis and Futuna, Western
          Sahara, Zambia, Zimbabwe. The Casino cannot guarantee successful
          processing of withdrawals in the event that Player breaches this
          Restricted Countries policy.
        </p>
        <p>
          <span className="font-semibold">2.5.</span> Please bear in mind some
          games may be unavailable in certain jurisdictions, as required by
          policies of game providers which may change from time to time.
        </p>
        <p>
          <span className="font-semibold">2.6.</span> Using VPN to bypass
          provider’s block is strictly prohibited and may lead to confiscation
          of winnings.
        </p>
        <p>
          <span className="font-semibold">2.7.</span> Age Verification Procedure
          for Crypto Assets. In order to be able to play for Crypto Assets,
          player must provide the Casino with the proof that it is at least 18
          years old or above (valid ID, Passport or Driving License or any other
          valid document). Players are not allowed to make deposits and/or place
          bets until the procedure is finished and their age is verified.
          Failure to provide the Casino with valid ID, Passport or Driving
          License will lead to Player’s account closure.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">3. AVAILABLE FORMS OF PAYMENT</h2>
        <p>
          <span className="font-semibold">3.1.</span> We only accept payments
          made in Crypto Assets as displayed on the Website.
        </p>
        <p>
          <span className="font-semibold">3.2.</span> The Player is aware of
          possible changing of the exchange rate for Crypto Assets depending on
          the market value. It is impossible to exchange Crypto Assets to any
          other currency (for example USD or EUR).
        </p>
        <p>
          <span className="font-semibold">3.3.</span> The details of how to
          perform particular transactions on Crypto Assets within the Website
          are each time determined by Website or third parties or websites of
          these third parties.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">4. FEES AND TAXES</h2>
        <p>
          <span className="font-semibold">4.1.</span> The Player is solely
          responsible for reporting and accounting for any taxes applicable to
          them under relevant laws for their participation in gambling including
          any winnings that they receive from the Casino.
        </p>
        <p>
          <span className="font-semibold">4.2.</span> Blockchain networks
          require the payment of a transaction fee (hereafter referred to as
          “Gas Fee”) for every transaction that occurs on the blockchain
          networks. The Player acknowledges and accepts that all transactions in
          blockchain technology (including transactions related to the Crypto
          Assets) are final and it is not possible to return.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">5. GAME RULES</h2>
        <p>
          <span className="font-semibold">5.1.</span> The Player confirms that
          it knows and understands the rules of games offered by the website. It
          is at Player’s discretion to know the payout percentage of each game.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">6. DISCLAIMER OF LIABILITIES</h2>
        <p>
          <span className="font-semibold">6.1.</span> The Player is completely
          aware of the fact that while gambling at the Website it takes the risk
          of losing money. The Company is not liable for any possible financial
          damage arising from the use of the website.
        </p>
        <p>
          <span className="font-semibold">6.2.</span> The Company is taking
          effective measures to protect Player’s private data from any
          unauthorized use and is only making it available to parties involved
          in providing of gambling services through the Website. Notwithstanding
          this, the Company is not responsible for how the information is
          further treated by third parties, for example third party software
          providers or affiliates. Treatment of Player’s private data by such
          parties is subject to Terms and Conditions of these parties, if any.
        </p>
        <p>
          <span className="font-semibold">6.3.</span> The Company is not liable
          for any hardware or software defects, poor Internet connection and
          other technical problems that have occurred on Player’s side.
        </p>
        <p>
          <span className="font-semibold">6.4.</span> In the unlikely case where
          a wager is confirmed or a payment is performed by us in error, the
          Company reserves the right to cancel all wagers accepted containing
          such an error, or to correct the mistake by re-settling all the wagers
          at the correct terms that should have been available at the time that
          the wager was placed in the absence of the error.
        </p>
        <p>
          <span className="font-semibold">6.5.</span> If the Casino mistakenly
          credit your Player Account with a bonus or winnings that do not belong
          to you, whether due to a technical issue, error in the pay tables,
          human error or otherwise, the amount and/ or the winnings from such
          bonus will remain the Casino property and will be deducted from your
          Player Account. If you have withdrawn funds that do not belong to you
          prior to us becoming aware of the error, the mistakenly paid amount
          will (without prejudice to other remedies and actions that may be
          available at law) constitute a debt owed by you to us. In the event of
          an incorrect crediting, you are obliged to notify us immediately by
          email.
        </p>
        <p>
          <span className="font-semibold">6.6.</span> The Casino, its directors,
          employees, partners, service providers:
          <br />
          <span className="block ml-4">
            (a) do not warrant that the software or the Website is/are fit for
            their purpose;
          </span>
          <br />
          <span className="block ml-4">
            (b) do not warrant that the software and Website are free from
            errors;
          </span>
          <br />
          <span className="block ml-4">
            (c) do not warrant that the Website and/or Games will be accessible
            without interruptions;
          </span>
          <br />
          <span className="block ml-4">
            (d) shall not be liable for any loss, costs, expenses, or damages,
            whether direct, indirect, special, consequential, incidental, or
            otherwise, arising in relation to Your use of the Website or Your
            participation in the Games.
          </span>
        </p>
        <p>
          <span className="font-semibold">6.7.</span> The Player hereby agrees
          to fully indemnify and hold harmless the Casino, its directors,
          employees, partners, and service providers for any cost, expense,
          loss, damages, claims and liabilities howsoever caused that may arise
          in relation to the Player’s use of the Website or participation in the
          games.
        </p>
        <p>
          <span className="font-semibold">6.8.</span> You acknowledge that the
          Casino shall be the final decision-maker of whether you have violated
          the Casino’s rules, terms or conditions in a manner that results in
          your suspension or permanent barring from participation in our
          Website.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">7. PLAYER ACCOUNT</h2>
        <p>
          <span className="font-semibold">7.1.</span> Each Player is allowed to
          create only one (1) personal Account per residence address, household,
          IP address, PC. Creating multiple Player Accounts by a single player
          can lead, at the sole discretion of the Casino, to termination of all
          such accounts and cancellation of all payouts to the Player. The
          Player shall not provide access to his/her Account or allow using the
          Website to any third party including but not limited to minors.
        </p>
        <p>
          <span className="font-semibold">7.2.</span> Besides, any returns,
          winnings or bonuses that the Player received or accumulated during the
          time when the duplicate Account was active the account will be
          forfeited and may be reclaimed by the Casino. The Player will return
          to the Casino on demand any funds which have been withdrawn from the
          duplicate Account.
        </p>
        <p>
          <span className="font-semibold">7.3.</span> The Website can be used
          solely for personal purposes and shall not be used for any type of
          commercial profit.
        </p>
        <p>
          <span className="font-semibold">7.4.</span> We reserve the right to
          contact Telegram (https://telegram.org/) or the Player directly to
          comply with the KYC procedure including verification of the Player's
          age. Withdrawals may be terminated until the Account is fully
          verified. We will make reasonable efforts trying to contact you
          regarding the withdrawal of the funds, but if we are not able to reach
          you (by Telegram or email) in two (2) weeks as from the date of the
          request for withdrawal, your account will be locked due to your
          failure to pass the KYC procedure.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">8. ANTI-FRAUD POLICY</h2>
        <p>
          <span className="font-semibold">8.1.</span> The Company has a strict
          anti-fraud policy. If the player is suspected of fraudulent actions,
          including but not limited to:
          <br />
          <span className="block ml-4">
            (a) participating in any type of collusion with other Players;
          </span>
          <br />
          <span className="block ml-4">
            (b) development and use of strategies aimed at unfaithful winnings;
          </span>
          <br />
          <span className="block ml-4">
            (c) fraudulent actions against other online casinos or payment
            providers;
          </span>
          <br />
          <span className="block ml-4">
            (d) revoking payments made in Crypto Assets or reporting an attempt
            to do so (e.g., to a cryptocurrency exchange);
          </span>
          <br />
          <span className="block ml-4">(e) other types of cheating;</span>
          <br />
          <span className="block ml-4">(f) providing of forged documents;</span>
          <br />
          <span className="block ml-4">(g) creating two or more Accounts;</span>
          <br />
          <span className="block ml-4">
            (h) or is known to have gone bankrupt in the country of residence,
          </span>
          <br />
          the Company reserves the right to terminate such a Player Account and
          suspend and/or cancel all payouts to the Player. This decision is at
          the sole discretion of the Company, and the Player will not be
          notified or informed about the reasons for such actions. The Company
          also reserves the right and may be obliged to inform applicable
          regulatory bodies of the fraudulent actions performed by the Player.
        </p>
        <p>
          <span className="font-semibold">8.2.</span> The Casino reserves the
          right to close the Player’s Account and to refund to the Player the
          Account balance, subject to the deduction of relevant withdrawal
          charges, at Casino’s absolute discretion and without any obligation to
          state a reason or give prior notice.
        </p>
        <p>
          <span className="font-semibold">8.3.</span> The Casino has zero
          tolerance to advantage play. Any player who will try to gain advantage
          of casino welcome offers or other promotions agrees that the Company
          reserves the right to void bonuses and any winnings from such bonuses,
          for the reasons of:
          <br />
          <span className="block ml-4">(a) use of stolen Crypto Assets;</span>
          <br />
          <span className="block ml-4">
            (b) revoking payments made in Crypto Assets or reporting an attempt
            to do so (e.g., to a cryptocurrency exchange);
          </span>
          <br />
          <span className="block ml-4">
            (c) more than one Account in order to get advantage from Casino
            promotions;
          </span>
          <br />
          <span className="block ml-4">
            (d) providing incorrect Player data;
          </span>
          <br />
          <span className="block ml-4">(e) providing of forged documents;</span>
          <br />
          <span className="block ml-4">
            (f) any other actions which may damage the Casino.
          </span>
        </p>
        <p>
          <span className="font-semibold">8.4.</span> The Casino reserves the
          right to retain payments, if suspicion or evidence exists of
          manipulation of the Casino system. Criminal charges will be brought
          against any Player or any other person(s), who has/have manipulated
          the Casino system or attempted to do so. The Casino reserves the right
          to terminate and/or change any games or events being offered on the
          Website.
        </p>
        <p>
          <span className="font-semibold">8.5.</span> Should the Player become
          aware of possible errors or incompleteness in the software, it agrees
          to refrain from taking advantage of them. Moreover, the Player agrees
          to report any error or incompleteness immediately to the Casino.
          Should the Player fail to fulfil the obligations stated in this
          clause, the Casino has a right to full compensation for all costs
          related to the error or incompleteness, including any costs incurred
          in association with the respective error/incompleteness and the failed
          notification by the Player.
        </p>
        <p>
          <span className="font-semibold">8.6.</span> If a Player requested a
          withdrawal of the funds, but the sum of made bets since last deposit
          is less than 3x (three) times the size of that deposit, the Casino
          reserves the right to charge the Player the costs of transaction
          processing, including deposits and withdrawals. This decision is at
          the sole discretion of the Casino.
        </p>
        <p>
          <span className="font-semibold">8.7.</span> In order to verify a
          Player's Account, the Casino will require documents (valid ID, utility
          bills, etc.). The Casino reserves the right to request an additional
          video verification in which the Player shows its documents.
        </p>
        <p>
          <span className="font-semibold">8.8.</span> The Casino is not a
          financial institution and thus should not be treated as such. Your
          Account will not bear any interests and no conversion or exchange
          services (including fiat -Crypto Assets exchange) will not be offered
          at any time.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">9. DEPOSIT PROCEDURE</h2>
        <p>
          <span className="font-semibold">9.1.</span> The Company offers
          payments in Crypto Assets only.
        </p>
        <p>
          <span className="font-semibold">9.2.</span> The Company does not
          accept third party payments. You must make deposits only from a
          wallet, cryptocurrency exchange or other payment methods that are
          registered in your own name. If we determine during the security
          checks that you have violated this condition, your winnings will be
          confiscated, and the original deposit will be returned to the owner of
          the payment Account. The Company is not responsible for the lost funds
          deposited from third party accounts.
        </p>
        <p>
          <span className="font-semibold">9.3.</span> The Casino reserves the
          right to specify a minimum value for deposits or withdrawals to or
          from the Casino. This information will be visible to the Player as
          part of their use of the Website.
        </p>
        <p>
          <span className="font-semibold">9.4.</span> Deposits made to Player
          Account must be successfully received and confirmed by the Casino,
          respective payment institution or provider.
        </p>
        <p>
          <span className="font-semibold">9.5.</span> If a deposit is denied,
          rejected or charged back for any reason, including insufficient funds,
          any resulting winnings will be voided permanently, and Casino will not
          be liable for losses incurred due to unsupported or denied deposits.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">10. WITHDRAWAL POLICY</h2>
        <p>
          <span className="font-semibold">10.1.</span> The Company offers
          withdrawals in Crypto Assets only.
        </p>
        <p>
          <span className="font-semibold">10.2.</span> The Casino reserves the
          right to specify a minimum value for deposits or withdrawals to or
          from the Casino. This information will be visible to the Player as
          part of their use of the Website.
        </p>
        <p>
          <span className="font-semibold">10.3.</span> The Casino reserves the
          right to check your identity prior to processing payouts and to hold
          any refund or withdrawals for the time needed to check your identity.
          In case you provide false or incomplete Personal Data, the withdrawal
          can be refused, and the Player Account terminated, of which you will
          be informed by Website or Telegram (https://telegram.org/). The Casino
          may be required to report the Player's actions to the applicable
          regulatory bodies.
        </p>
        <p>
          <span className="font-semibold">10.4.</span> The Casino reserves the
          right to transfer funds in a way different from the payment method
          specified by the Gambler if such need occurs.
        </p>
        <p>
          <span className="font-semibold">10.5.</span> The Casino aims to
          process all withdrawal requests within 72 hours. Withdrawal times
          depend on the chosen payment method and verification status.
        </p>
        <p>
          <span className="font-semibold">10.6.</span> Please keep in mind the
          Casino is not a financial institution. Your Account will thus not bear
          any interests and no conversion or exchange services will be offered
          at any time.
        </p>
        <p>
          <span className="font-semibold">10.7</span> All costs that may occur
          upon refund or withdrawal procedure shall be borne by the Player.
        </p>
        <p>
          <span className="font-semibold">10.8.</span> You can contact our
          support team directly via the Website to enquire about withdrawals.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">11. DORMANT ACCOUNTS</h2>
        <p>
          <span className="font-semibold">11.1.</span> An inactive Account is a
          Player Account which a player has not logged into or logged out of for
          12 (twelve) consecutive months.
        </p>
        <p>
          <span className="font-semibold">11.2.</span> The Casino reserves the
          right to delete an inactive Player Account. In such case, the Casino
          shall be entitled to charge a penalty in the amount of the Player's
          balance, as of the date the Casino deletes the Account, unless the
          Player has previously withdrawn all funds from the Account.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">12. KYC POLICY</h2>
        <p>
          <span className="font-semibold">12.1.</span> By agreeing to these
          Terms, you authorize the Company to regularly conduct various checks
          at the Company’s sole discretion or if required by third parties
          (including regulatory agencies), in order to verify your identity and
          contact information ("Verification").
        </p>
        <p>
          <span className="font-semibold">12.2.</span> The Company reserves the
          right to suspend your account if we are unable to ascertain that you
          have reached the Eligible Age. If, while using the Website services
          associated with gambling, you were below the Eligible Age:
          <br />
          <span className="block ml-4">(a) Your account will be closed;</span>
          <br />
          <span className="block ml-4">
            (b) All transactions made during this period of time will be
            annulled, and the money deposited to your account will be returned;
          </span>
          <br />
          <span className="block ml-4">
            (c) All your bets made during this period of time will be cancelled
            and refunded;
          </span>
          <br />
          <span className="block ml-4">
            (d) All your winnings accumulated during the period of time when
            your age was under the Eligible Age will be lost, and you will have
            to refund the Company with all the amounts withdrawn from your
            account at the Company’s request;
          </span>
          <br />
          <span className="block ml-4">
            (e) A deposit made to your account will not be refunded due to
            Company policy violation.
          </span>
        </p>
        <p>
          <span className="font-semibold">12.3.</span> In the event that the
          information provided by you proves to be false, incomplete, inaccurate
          or misleading, and also if the information specified during
          registration does not correspond to your ID or passport data, these
          Terms and Conditions shall be considered violated, and the Company may
          immediately close your account and cancel all funds in your balance,
          in addition to other actions at the Company’s sole discretion,
          including refusal to provide use of the Website services.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">13. REFUND POLICY</h2>
        <p>
          <span className="font-semibold">13.1.</span> The Player acknowledges
          and accepts that all transactions in blockchain technology (including
          transactions related to the Crypto Assets) are final and it is not
          possible to return.
        </p>
        <p>
          <span className="font-semibold">13.2.</span> With the exception of the
          situation referred to in 8.2 of these Terms and Conditions, the Casino
          indicates that no refunds can be made with respect to transactions
          made on the Website.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">14. COMPLAINTS</h2>
        <p>
          <span className="font-semibold">14.1.</span> Complaints are handled in
          the support department and escalated in the organization of the Casino
          in the case that support personnel did not solve the case immediately.
          You shall be informed about the state of the complaint to a reasonable
          level.
        </p>
        <p>
          <span className="font-semibold">14.2.</span> The Casino is to
          acknowledge a complaint started by the account holder only. It is
          forbidden to, and therefore you cannot assign, transfer, hand over or
          sell your complaint to the third party.
        </p>
        <p>
          <span className="font-semibold">14.3.</span> Casino will dismiss the
          complaint if the matter is handed over to be conducted by the third
          party and not the original account owner.
        </p>
        <p>
          <span className="font-semibold">14.4.</span> In the event of any
          dispute, you agree that the server logs and records shall act as the
          final authority in determining the outcome of any claim. You agree
          that in the unlikely event of a disagreement between the result that
          appears on your screen and the Website, the result that was logged on
          the Website will prevail, and you acknowledge and agree that our
          records will be the final authority in determining the terms and
          circumstances of your participation in the relevant online gaming
          activity and the results of this participation.
        </p>
        <p>
          <span className="font-semibold">14.5.</span> When we wish to contact
          you regarding such a dispute, we will do so by using any of the
          contact details provided in your Player Account (in particular but not
          exclusively your Telegram account).
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">15. NON-TRANSFERABILITY</h2>
        <p>
          <span className="font-semibold">15.1.</span> You cannot assign, pledge
          or transfer ownership under any title whatsoever to claims arising
          from these Terms and Conditions, the use of the Website or
          participation in the Games against the Casino without consent of the
          Casino. This prohibition includes the transfer of any assets of value
          of any kind, including but not limited to ownership of accounts,
          winnings, deposits, bets, rights and/ or claims in connection with
          these assets, legal, commercial, or otherwise. The prohibition on said
          transfers also includes however is not limited to the encumbrance,
          pledging, assigning, usufruct, trading, brokering, hypothecation and/
          or gifting in cooperation with a fiduciary or any other third party,
          company, natural or legal individual, entity in any way shape or form.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">16. ARBITRATION</h2>
        <p>
          <span className="font-semibold">16.1.</span> All disputes which may
          arise between you and the Casino including their successors in title
          under general or special title as a result of these Terms and
          Conditions or as a result of further agreements and other acts in
          connection with these Terms and Conditions shall be settled
          exclusively by arbitration at the place of the Casino's current
          headquarters.
        </p>
        <p>
          <span className="font-semibold">16.2.</span> At the same time, you
          acknowledge and accepts that disputes arising therefrom may only be
          considered on the basis of an individual case of the Player. Casino is
          not obliged to settle disputes as collective cases or collective
          actions.
        </p>
      </section>
      <section className="mt-6">
        <h2 className="font-bold text-md">17. FINAL PROVISIONS</h2>
        <p>
          <span className="font-semibold">17.1.</span> To ensure the proper
          operation of the Website, the Casino, in its sole discretion, has the
          right to update the Website and services without prior notice. In
          order to continue using the services and the Website after an update,
          the Player must accept such updates, including periodic updates to
          third party software (e.g. Telegram).
        </p>
        <p>
          <span className="font-semibold">17.2.</span> You acknowledge and
          accepts that the Casino may transfer the rights and obligations
          resulting from the implementation of the provisions hereof to another
          entity, person or third party.
        </p>
        <p>
          <span className="font-semibold">17.3.</span> No legal basis or
          incompleteness of any of the provisions contained herein does not mean
          that the entire document is null and void. Such provisions shall be
          amended to the ones that best reflect their meaning and purpose.
        </p>
        <p>
          <span className="font-semibold">17.4.</span> These Terms and
          Conditions are effective as of October 30, 2024.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
