import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";
import linkIcon from "../assets/images/link_icon.svg";
import { useReflink } from "../utils/socialMedia";
import reflinkCardBackground from "../assets/images/reflink_card_background.png";
import Button from "./Button";

const ReflinkCard = () => {
  const [isCopied, setCopied] = useState(false);
  const reflink = useReflink();

  return (
    <div
      id="reflink-card"
      style={{
        overflow: "hidden",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        aspectRatio: "392/185",
        backgroundImage: `url(${reflinkCardBackground})`,
      }}
      className="w-full"
    >
      <div className="flex flex-grow flex-col justify-between items-start gap-2 px-5 pb-5 pt-5 h-full">
        <div className="flex flex-col gap-1">
          <div className="gotham-bold text-xl">Referral Rewards</div>
          <div className="gotham-medium text-sm opacity-45 flex flex-col gap-1">
            <ul className="list-disc list-inside">
              <li>
                Get <span className="font-semibold">10% USD</span> commission
              </li>
              <li>
                Earn <span className="font-semibold">2000 CC</span> per friend
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-row w-full justify-start items-center gap-2">
          <div className="w-2/3">
            <CopyToClipboard text={reflink}>
              <Button
                icon={linkIcon}
                variant="third"
                text={reflink}
                onClick={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                }}
              />
            </CopyToClipboard>
          </div>
          <div className="w-1/3">
            <CopyToClipboard text={reflink}>
              <Button
                variant="third"
                text={isCopied ? "Copied!" : "Copy link"}
                onClick={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                }}
              />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReflinkCard;
