import React from "react";
import { ImageButton } from "./ImageButton";

function GameCard({
  imageSrc,
  onClick,
  alt,
  shadowColor = "rgba(0,0,0,0.25)",
}) {
  return (
    <div className="w-max h-auto">
      <ImageButton
        onClick={onClick}
        imageSrc={imageSrc}
        alt={alt}
        style={{
          filter: `drop-shadow(0px 4px 10px ${shadowColor})`,
        }}
      />
    </div>
  );
}

export default GameCard;
