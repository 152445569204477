import { ImageButton } from "./ImageButton";
import plusIcon from "../assets/images/plus_icon.svg";
import { Tooltip } from "react-tooltip";

const BalanceIndicator = ({
  icon,
  balance,
  showPlusSign = false,
  onPlusClick,
  tooltipText,
  tooltipId,
}) => {
  return (
    <>
      <Tooltip id={tooltipId}>{tooltipText}</Tooltip>
      <div
        className="flex flex-row justify-start items-center rounded-lg border-2 bg-[#21127B] h-[calc(2rem+5px)]"
        style={{
          borderColor: "rgba(66, 43, 210, 0.75)",
        }}
      >
        <div className="flex flex-row items-center">
          <div data-tooltip-id={tooltipId} data-tooltip-place="bottom">
            {icon}
          </div>
          <div
            className="h-5 rounded-lg bg-[#21127B]"
            style={{
              borderColor: "rgba(66, 43, 210, 0.75)",
              borderWidth: "0.93px",
              width: "2px",
            }}
          ></div>
        </div>

        <div className="relative flex flex-row flex-grow items-center justify-center w-[13vw] text-xs font-bold mx-1">
          <div className="overflow-hidden whitespace-nowrap text-ellipsis">
            {balance}
          </div>
        </div>
        {showPlusSign && (
          <>
            <div className="mr-2">
              <ImageButton
                onClick={onPlusClick}
                imageSrc={plusIcon}
                className="w-3 h-3"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BalanceIndicator;
