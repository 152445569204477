import TextWithStroke from "./TextWithStroke";
import balanceIndicatorBackground from "../../assets/images/slot_machine/balance_indicator_background.png";

const BalanceIndicator = ({ title, idToPass }) => {
  return (
    <div
      className="rounded-full h-16 flex flex-col pt-3 pb-6 items-center justify-between bg-no-repeat bg-center bg-cover aspect-[384/160] mb-[-1.5rem]"
      style={{
        backgroundImage: `url(${balanceIndicatorBackground})`,
      }}
    >
      <TextWithStroke
        text={title}
        strokeColor="#F7D0FF"
        className="text-xs opacity-50 mt-[0.1rem]"
      />

      <TextWithStroke
        strokeColor="#F7D0FF"
        className="text-xl mt-[-0.3rem]"
        idToPass={idToPass}
      />
    </div>
  );
};

export default BalanceIndicator;
