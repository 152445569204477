import { useLocation } from "react-router-dom";
import TermsOfUseScreen from "../screens/TermsOfUseScreen";
import UnauthorizedScreen from "../screens/UnauthorizedScreen";
import { setSelectedWalletTabIndex } from "../features/generalSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useGetScreenIfNotAuthorized = () => {
    const location = useLocation();

    switch (location.pathname) {
        case "/terms-of-use":
            return <TermsOfUseScreen hasTermsBeenAccepted={true} />;
        default:
            return <UnauthorizedScreen />;
    };
}

export const useNavigateToDeposits = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return () => {
        dispatch(setSelectedWalletTabIndex(0));
        navigate("/wallet");
        setTimeout(() => {
            document.getElementById("deposit-data")?.scrollIntoView({
                behavior: "smooth",
            });
        }, 100);
    };
};