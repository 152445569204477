import TextWithStroke from "./TextWithStroke";
import textButtonBackground from "../../assets/images/slot_machine/text_button_background.png";

const TextButton = ({ text, onClick, isDisabled }) => (
  <button
    disabled={isDisabled}
    onClick={onClick}
    className="rounded-full h-10 w-full flex items-center justify-center bg-no-repeat bg-center bg-cover"
    style={{
      boxShadow: "0 7px 3px rgba(0, 0, 0, 0.2)",
      backgroundImage: `url(${textButtonBackground})`,
    }}
  >
    <TextWithStroke
      text={text}
      strokeColor="rgba(0, 0, 0, 0.3)"
      className="text-md z-[3]"
      style={{
        textShadow: "0 3px 0px rgba(0, 0, 0, 0.3)", // y=4, 30% black opacity
        mixBlendMode: "overlay",
      }}
    />
    <TextWithStroke
      text={text}
      strokeColor="rgba(0, 0, 0, 0.3)"
      className="text-md absolute z-[2]"
      style={{
        textShadow: "0 3px 0px rgba(0, 0, 0, 0.3)", // y=4, 30% black opacity
        mixBlendMode: "overlay",
      }}
    />
  </button>
);

export default TextButton;
