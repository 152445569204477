import { useDispatch, useSelector } from "react-redux";
import { setAirdropPopUpOpen } from "../features/generalSlice";
import ModalPopUp from "./ModalPopUp/ModalPopUp";

const AirdropPopUp = ({}) => {
  const dispatch = useDispatch();
  const isAirdropPopUpOpen = useSelector(
    (state) => state.general.isAirdropPopUpOpen
  );

  return (
    <ModalPopUp
      isOpen={isAirdropPopUpOpen}
      close={() => dispatch(setAirdropPopUpOpen(false))}
      title="CasinoCoins"
      showConfetti
    >
      <div className="flex flex-col gap-3 text-sm items-center justify-center text-center leading-relaxed max-w-lg mx-auto">
        <p className="font-semibold text-sm">
          Imagine free gifts from the sky — crypto airdrops make it real with
          digital tokens!
        </p>

        <h3 className="font-semibold text-base mt-2">Casino Coins:</h3>
        <ul className="text-sm list-disc list-inside text-left">
          <li>
            <span className="font-semibold">Free to Claim:</span> Grab them in
            our airdrop.
          </li>
          <li>
            <span className="font-semibold">Gameplay Power:</span> Use them for
            exciting slot games.
          </li>
          <li>
            <span className="font-semibold">Winning Potential:</span> Every spin
            could be a big win!
          </li>
        </ul>

        <h3 className="font-semibold text-base mt-4">Future Potential:</h3>
        <ul className="text-sm list-disc list-inside text-left">
          <li>
            <span className="font-semibold">DEX Listing:</span> Soon tradable on
            decentralized exchanges.
          </li>
          <li>
            <span className="font-semibold">Trading Opportunity:</span> Trade
            for potential profit.
          </li>
          <li>
            <span className="font-semibold">Growing Ecosystem:</span> Increasing
            utility and value.
          </li>
        </ul>

        <p className="font-semibold text-lg mt-6">
          Claim your Casino Coins now!
        </p>
      </div>
    </ModalPopUp>
  );
};

export default AirdropPopUp;
