import DropdownMenu from "../../components/DropdownMenu";
import dollarCoinIcon from "../../assets/images/dollar_coin_icon.png";
import { useMemo, useState } from "react";
import tetherLogo from "../../assets/images/tether_logo.png";
import trcTetherLogo from "../../assets/images/trc_tether_logo.png";
import InputField from "../../components/InputField";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setAddress, setAmount } from "../../features/withdrawalSlice";
import { useRequestPayoutMutation } from "../../services/official";
import { useGetUserQuery } from "../../services/official";
import WithdrawalStatusPopUp from "../../components/WithdrawalStatusPopUp/WithdrawalStatusPopUp";

const WithdrawalTabContent = () => {
  const dispatch = useDispatch();
  const currencies = ["USDT (ERC-20)", "USDT (TRC-20)"];
  const { amount, address } = useSelector((state) => state.withdrawal);
  const icons = [
    <img src={tetherLogo} className="h-7 w-auto" alt="USDT (ERC-20) Logo" />,
    <img src={trcTetherLogo} className="h-7 w-auto" alt="USDT (TRC-20) Logo" />,
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [requestPayout, result] = useRequestPayoutMutation();
  const { id } = useSelector((state) => state.user);
  const { data } = useGetUserQuery(id);

  const currencyIds = ["TetherErc20", "TetherTrc20"];

  const usdBalance = useMemo(() => {
    const chipBalance =
      data?.withdrawableChipBalance ?? 0 + data?.onlyForPlayChipBalance ?? 0;
    return (chipBalance / 57).toFixed(2);
  }, [data]);
  const isAddressValid = useMemo(() => address.length > 0, [address]);
  const isAmountValid = useMemo(() => {
    const decimalPattern = /^\d+(\.\d{1,2})?$/;
    return amount.length > 0 && decimalPattern.test(amount);
  }, [amount]);

  return (
    <>
      <WithdrawalStatusPopUp
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        result={result}
      >
        <div className="flex flex-col gap-3">
          <DropdownMenu
            icons={icons}
            label="Choose a method (Minimum withdrawal: $10)"
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            items={currencies}
          />

          <InputField
            onChange={(e) => dispatch(setAddress(e.target.value))}
            label="Wallet address"
            placeholder="Paste your wallet address"
          />
          <InputField
            onChange={(e) => dispatch(setAmount(e.target.value))}
            type="number"
            inputMode="decimal"
            pattern="^\d+(\.\d{1,2})?$"
            trailingText={`Winnings: $${usdBalance}`}
            icon={<img src={dollarCoinIcon} className="ml-3 h-6" />}
            label="Withdrawal amount"
            placeholder="Enter the amount"
            errorMessage={
              isAmountValid || amount.length == 0
                ? null
                : "Enter a valid USD decimal"
            }
          />
        </div>
      </WithdrawalStatusPopUp>
      <div className="mt-8">
        <Button
          isDisabled={!isAddressValid || !isAmountValid}
          text="Withdraw"
          onClick={async () => {
            try {
              setModalOpen(true);
              const result = await requestPayout({
                address,
                chipsToWithdraw: Math.floor(
                  Number.parseFloat(amount.replace(",", ".")) * 57
                ),
                currency: currencyIds[selectedIndex],
              }).unwrap();
              if (result.isSuccess) {
                dispatch(setAmount(""));
                dispatch(setAddress(""));
              }
            } catch (e) {}
          }}
        />
      </div>
      <div className="text-xs text-center mb-[0.5rem] opacity-85 text-red-500 font-bold">
        {data.hasMadeDeposit
          ? "Transaction fee will be deducted from the amount."
          : "Make a deposit to enable withdrawals."}
      </div>
    </>
  );
};

export default WithdrawalTabContent;
