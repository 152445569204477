import { useSelector, useDispatch } from "react-redux";
import CongratulationsPopUpContent from "./CongratulationsPopUpContent";
import ModalPopUp from "./ModalPopUp/ModalPopUp";
import { setRewardPopUpStatus } from "../features/generalSlice";

const RewardPopUp = ({}) => {
  const dispatch = useDispatch();
  const { rewardPopUpStatus } = useSelector((state) => state.general);

  return (
    <ModalPopUp
      isOpen={rewardPopUpStatus?.isOpen}
      close={() => {
        dispatch(
          setRewardPopUpStatus({
            isOpen: false,
            isSuccess: false,
            isLoading: false,
            reward: null,
            current: null,
          })
        );
      }}
      title="Congratulations!"
      showConfetti
    >
      <CongratulationsPopUpContent
        result={rewardPopUpStatus}
        reward={rewardPopUpStatus.reward}
        currency={rewardPopUpStatus.currency}
      />
    </ModalPopUp>
  );
};

export default RewardPopUp;
