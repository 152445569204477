import TaskCard from "./TaskCard";
import personIcon from "../assets/images/person_icon.svg";
import { useSelector } from "react-redux";
import {
  useGetReferralStatisticsQuery,
  useGetUnclaimedRewardsQuery,
  useClaimRewardMutation,
} from "../services/official";
import { useCallback, useMemo, useState } from "react";
import CongratulationsPopUpContent from "./CongratulationsPopUpContent";
import ModalPopUp from "./ModalPopUp/ModalPopUp";

const InviteFriendsTaskCard = ({}) => {
  const { id: userId } = useSelector((state) => state.user);
  const { data } = useGetReferralStatisticsQuery({ userId });
  const { data: unclaimedRewards } = useGetUnclaimedRewardsQuery({ userId });
  const [claimReward, result] = useClaimRewardMutation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [reward, setReward] = useState(null);

  const isGoalCompleted = useMemo(() => {
    return (
      data !== null &&
      data !== undefined &&
      data?.totalReferredUsers >= data?.nextReferralGoal
    );
  }, [data]);

  const onClaimReward = useCallback(() => {
    const oldestUnclaimedReward = unclaimedRewards?.unclaimedRewards
      ?.filter((reward) => reward?.type === "Referral")
      ?.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))[0];

    claimReward({ rewardId: oldestUnclaimedReward?.id });
    setReward(oldestUnclaimedReward?.rewardAmount);
    setModalOpen(true);
  }, [unclaimedRewards, claimReward]);

  return (
    <>
      <TaskCard
        icon={personIcon}
        title={"Invite friends"}
        reward={data?.nextReferralReward}
        progress={{
          unitsCompleted: data?.totalReferredUsers,
          goal: data?.nextReferralGoal,
        }}
        isCompleted={isGoalCompleted}
        buttonText={isGoalCompleted && "Claim"}
        onButtonClick={onClaimReward}
        iconClassName={"w-5 opacity-30"}
      />
      <ModalPopUp
        isOpen={isModalOpen}
        close={() => setModalOpen(false)}
        title="Congratulations!"
        showConfetti
      >
        <CongratulationsPopUpContent result={result} reward={reward} />
      </ModalPopUp>
    </>
  );
};

export default InviteFriendsTaskCard;
