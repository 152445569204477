import SpinBar from "./SpinBar";
import TextButton from "./TextButton";
import TextWithStroke from "./TextWithStroke";
import { useDispatch, useSelector } from "react-redux";
import { useUserBalance } from "../../utils/user";
import { setBetSize } from "../../features/slotsSlice";
import { useCallback } from "react";

const SlotsOptions = ({ onSpin, onAutoplay }) => {
  const dispatch = useDispatch();
  const { betSize } = useSelector((state) => state.slots);
  const { chipBalance } = useUserBalance();
  const doubleBetSize = useCallback(() => {
    const newBetSize = Math.min(betSize * 2, chipBalance);
    dispatch(setBetSize(newBetSize));
  }, [dispatch, betSize]);

  const maxBetSize = useCallback(() => {
    dispatch(setBetSize(chipBalance));
  }, [dispatch, chipBalance]);

  const { isSpinning, isLocked } = useSelector((state) => state.slots);

  return (
    <div className="w-full flex flex-col items-center gap-2">
      <div className="w-full">
        <SpinBar onSpin={onSpin} />
      </div>
      <div className="w-[95vw] h-14 flex flex-row gap-4 mt-2 justify-center">
        <TextButton text="Double bet" onClick={doubleBetSize} />
        <TextButton
          text="Auto 3x"
          onClick={onAutoplay}
          isDisabled={isSpinning || isLocked}
        />
        <TextButton text="Max bet" onClick={maxBetSize} />
      </div>
      <div>
        <TextWithStroke
          text={"Minimum bet size is 10"}
          strokeColor="rgba(0, 0, 0, 0.3)"
          className="text-md z-[3] opacity-70"
          style={{
            textShadow: "0 3px 0px rgba(0, 0, 0, 0.3)", // y=4, 30% black opacity
            mixBlendMode: "overlay",
          }}
        />
      </div>
    </div>
  );
};

export default SlotsOptions;
