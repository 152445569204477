import React from "react";
import NavigationItem from "./NavigationItem";
import walletIcon from "../assets/images/wallet_icon.svg";
import selectedWalletIcon from "../assets/images/selected_wallet_icon.svg";
import homeIcon from "../assets/images/home_icon.svg";
import selectedHomeIcon from "../assets/images/selected_home_icon.svg";
import earnIcon from "../assets/images/earn_icon.svg";
import selectedEarnIcon from "../assets/images/selected_earn_icon.svg";
import referralIcon from "../assets/images/referral_icon.svg";
import selectedReferralIcon from "../assets/images/selected_referral_icon.svg";
import prizeIcon from "../assets/images/prize_icon.svg";
import selectedPrizeIcon from "../assets/images/selected_prize_icon.svg";
import { useLocation } from "react-router-dom";

function NavigationBar() {
  const location = useLocation();
  return (
    <>
      <nav
        className="flex flex-col justify-center w-full z-[999] bottom-0 fixed px-5"
        style={
          location.pathname.startsWith("/game/") ? { display: "none" } : null
        }
      >
        <div className="w-full bg-[#21127B] rounded-xl border-2 border-[rgba(66,43,210,0.75)] mb-5 mt-2">
          <div className="flex flex-row justify-between items-center px-2 pt-2 pb-[calc(0.5rem+8px)]">
            <NavigationItem
              to="/"
              icon={homeIcon}
              selectedIcon={selectedHomeIcon}
              label="Home"
            />
            <NavigationItem
              to="/wallet"
              icon={walletIcon}
              selectedIcon={selectedWalletIcon}
              label="Wallet"
            />
            <NavigationItem
              to={"/earn"}
              icon={earnIcon}
              selectedIcon={selectedEarnIcon}
              label="Earn"
            />
            <NavigationItem
              to={"/referral"}
              icon={referralIcon}
              selectedIcon={selectedReferralIcon}
              label="Referral"
            />
            <NavigationItem
              to={"/prize"}
              icon={prizeIcon}
              selectedIcon={selectedPrizeIcon}
              label="Prize"
            />
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavigationBar;
