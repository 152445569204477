import { useMemo } from "react";

const IconTabBar = ({
  leftIcon,
  middleLeftIcon,
  middleRightIcon,
  rightIcon,
  leftTabContent,
  middleLeftTabContent,
  middleRightTabContent,
  rightTabContent,
  selectedTabIndex,
  setSelectedTabIndex,
}) => {
  const tabContents = [
    leftTabContent,
    middleLeftTabContent,
    middleRightTabContent,
    rightTabContent,
  ];

  const colors = useMemo(() => {
    if (selectedTabIndex === 0) {
      return ["#130960", "#261782", "#35249E", "#4633BA"];
    } else if (selectedTabIndex === 1) {
      return ["#35249E", "#130960", "#261782", "#4633BA"];
    } else if (selectedTabIndex === 2) {
      return ["#35249E", "#261782", "#130960", "#4633BA"];
    } else {
      return ["#35249E", "#261782", "#4633BA", "#130960"];
    }
  }, [selectedTabIndex]);

  const leftTabPadding = useMemo(() => {
    switch (selectedTabIndex) {
      case 0:
        return { paddingRight: "" };
      case 1:
        return { paddingRight: "4%" };
      case 2:
        return { paddingRight: "4%" };
      default:
        return { paddingRight: "" };
    }
  }, [selectedTabIndex]);

  const middleLeftTabPadding = useMemo(() => {
    switch (selectedTabIndex) {
      case 0:
        return { paddingLeft: "", paddingRight: "" };
      case 1:
        return { paddingLeft: "4%", paddingRight: "4%" };
      case 2:
        return { paddingLeft: "", paddingRight: "4%" };
      case 3:
        return { paddingLeft: "4%", paddingRight: "" };
      default:
        return {};
    }
  }, [selectedTabIndex]);

  const middleRightTabPadding = useMemo(() => {
    switch (selectedTabIndex) {
      case 0:
        return { paddingLeft: "8%", paddingRight: "" };
      case 1:
        return { paddingLeft: "", paddingRight: "" };
      case 2:
        return { paddingLeft: "4%", paddingRight: "4%" };
      case 3:
        return { paddingLeft: "", paddingRight: "3%" };
      default:
        return {};
    }
  }, [selectedTabIndex]);

  const rightTabPadding = useMemo(() => {
    switch (selectedTabIndex) {
      case 0:
        return { paddingLeft: "18%" };
      case 1:
        return { paddingLeft: "18%" };
      case 2:
        return { paddingLeft: "8%" };
      case 3:
        return { paddingLeft: "" };
      default:
        return { paddingLeft: "" };
    }
  }, [selectedTabIndex]);

  console.log(selectedTabIndex);

  return (
    <>
      <div
        className="w-2/3 flex items-center min-h-12 rounded-t-2xl mt-1 relative"
        style={{
          backgroundColor: "rgba(33, 18, 123, 0.45)",
        }}
      >
        {/* Left Tab */}
        <div
          className="absolute flex justify-center items-center rounded-t-2xl left-0 h-full"
          style={{
            backgroundColor: colors[0],
            zIndex: selectedTabIndex === 0 ? 4 : 3,
            width: selectedTabIndex === 0 ? `28%` : "34%",
            ...leftTabPadding,
          }}
          onClick={() => setSelectedTabIndex(0)}
        >
          <div
            style={{
              opacity: selectedTabIndex === 0 ? 1 : 0.3,
            }}
          >
            {leftIcon}
          </div>
        </div>

        {/* Middle Left Tab */}
        <div
          className="absolute flex justify-center items-center rounded-t-2xl left-1/4 h-full"
          style={{
            backgroundColor: colors[1],
            zIndex: selectedTabIndex === 1 ? 5 : selectedTabIndex == 2 ? 4 : 2,
            width: selectedTabIndex === 1 ? `28%` : "34%",
            ...middleLeftTabPadding,
          }}
          onClick={() => setSelectedTabIndex(1)}
        >
          <div
            style={{
              opacity: selectedTabIndex === 1 ? 1 : 0.3,
            }}
          >
            {middleLeftIcon}
          </div>
        </div>

        {/* Middle Right Tab */}
        <div
          className="absolute flex justify-center items-center rounded-t-2xl left-1/2 h-full"
          style={{
            backgroundColor: colors[2],
            zIndex: selectedTabIndex == 2 ? 5 : selectedTabIndex == 1 ? 4 : 1,
            width: selectedTabIndex === 2 ? `28%` : "34%",
            ...middleRightTabPadding,
          }}
          onClick={() => setSelectedTabIndex(2)}
        >
          <div
            style={{
              opacity: selectedTabIndex === 2 ? 1 : 0.3,
            }}
          >
            {middleRightIcon}
          </div>
        </div>

        {/* Right Tab */}
        <div
          className="absolute flex justify-center items-center rounded-t-2xl right-0 h-full"
          style={{
            backgroundColor: colors[3],
            zIndex: selectedTabIndex === 3 ? 4 : selectedTabIndex == 1 ? 3 : 0,
            width: selectedTabIndex === 3 ? `28%` : "34%",
            ...rightTabPadding,
          }}
          onClick={() => setSelectedTabIndex(3)}
        >
          <div
            style={{
              opacity: selectedTabIndex === 3 ? 1 : 0.3,
            }}
          >
            {rightIcon}
          </div>
        </div>
      </div>

      <div
        className="bg-[#130960] w-full rounded-b-xl rounded-tr-xl overflow-hidden"
        style={{
          height: "calc(100vh - 20.5rem)",
        }}
      >
        <div className="h-full overflow-y-auto">
          {tabContents[selectedTabIndex]}
        </div>
      </div>
    </>
  );
};

export default IconTabBar;
