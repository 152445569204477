import { setDisplayedAirDropBalance, setDisplayedChipBalance, setLocked, setSpinning } from "../features/slotsSlice";
import { useRequestNewSlotsGameMutation } from "../services/official";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "../services/official";
import { useDispatch } from "react-redux";
import store from "../app/store";

function getBetSizeErrorMessage(betSize, user) {
    const parsedBetSize = parseInt(betSize, 10);
    if (parsedBetSize < 10) return "The minimum bet size is 10 chips";
    if (
        parsedBetSize >
        user?.withdrawableChipBalance + user?.onlyForPlayChipBalance
    )
        return "Insufficient balance";

    return null;
}

function validateInteger(value) {
    const parsedValue = parseInt(value, 10);
    return Number.isInteger(parsedValue) && parsedValue.toString() === value;
}

function useValidateBetSize() {
    const { id } = useSelector((state) => state.user);
    const { data: user } = useGetUserQuery(id);
    const { betSize } = useSelector((state) => state.slots);

    const errorMessage = getBetSizeErrorMessage(betSize, user);

    return { isValid: betSize !== "" && errorMessage === null, errorMessage };
}

function useSpin({ slotMachineRef, minorWinEffectRef, airdropEffectRef }) {
    const { betSize } = useSelector((state) => state.slots);
    const { isValid: isBetSizeValid } = useValidateBetSize();
    const [requestSlotsGame] = useRequestNewSlotsGameMutation();
    const showWinEffectIfNeeded = useShowWinEffectIfNeeded({ slotMachineRef, minorWinEffectRef, airdropEffectRef });
    const dispatch = useDispatch();

    return async () => {
        if (!slotMachineRef.current || !isBetSizeValid) return;

        dispatch(setSpinning(true));
        const lastSubmittedBetSize = betSize;

        var result = await requestSlotsGame({
            betSizeInChips: betSize,
        });
        if (result.error?.status === 400) {
            dispatch(setSpinning(false));
            return;
        }
        let currentChipBalance = store.getState().slots.displayedChipBalance;
        dispatch(setDisplayedChipBalance(currentChipBalance - betSize));

        await slotMachineRef.current?.spin(result.data);
        await showWinEffectIfNeeded(result, lastSubmittedBetSize);
        setTimeout(() => dispatch(setSpinning(false)), 500);
        currentChipBalance = store.getState().slots.displayedChipBalance;
        const currentAirDropBalance = store.getState().slots.displayedAirDropBalance;

        dispatch(setDisplayedChipBalance(result.data.winningAmountInChips + currentChipBalance));
        dispatch(setDisplayedAirDropBalance(result.data.airdropPointsReward + currentAirDropBalance));
    };
};

function useShowWinEffectIfNeeded({ minorWinEffectRef, slotMachineRef, airdropEffectRef }) {
    return async (result, lastSubmittedBetSize) => {
        if (result.data.airdropPointsReward > 0) {
            await Promise.all([
                slotMachineRef.current.showAirdropPoints(result.data),
                airdropEffectRef.current.play(),
            ]);
        }

        if (result.data.winningAmountInChips > lastSubmittedBetSize) {
            await slotMachineRef.current.showPayLinesWithRewards(
                result.data,
                false
            );
            await slotMachineRef.current.updateBigUsdCounter(
                result.data
            );

        } else if (result.data.winningAmountInChips > 0) {
            await Promise.all([
                slotMachineRef.current.showPayLinesWithRewards(result.data, true),
                minorWinEffectRef.current.play(),
            ]);
        }
    }
};

function useAutoplay({ slotMachineRef, minorWinEffectRef, airdropEffectRef }) {
    const spin = useSpin({ slotMachineRef, minorWinEffectRef, airdropEffectRef });
    const dispatch = useDispatch();

    return async () => {
        dispatch(setLocked(true));
        for (let i = 0; i < 3; i++) {
            await spin();
            await new Promise((resolve) => setTimeout(resolve, 500));
        }

        dispatch(setLocked(false));
    }
}

export { validateInteger, useValidateBetSize, useSpin, useAutoplay, useShowWinEffectIfNeeded }